import React from "react";
import "./App.css";
import Root from "./Root";
import Login from "./Login";
import Success from "./Success";
import Welcome from "./Welcome";
import Pesquisa from "./Pesquisa";
import Oportunities from "./Oportunities";
import Vantagens from "./Vantagens";

import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as appActions } from "./ducks/app";
import { Creators as authActions } from "./ducks/auth";

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...appActions, ...authActions }, dispatch);
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.app.token,
  };
};

const isAuthenticated = (authType, rest) => {
  if (authType === "salesforce_Authenticated") {
    try {
      const authData = JSON.parse(window.localStorage.getItem("authData"));
      if (authData) {
        const sessionExpires = authData.sessionExpires;
        if (sessionExpires > new Date().getTime()) {
          rest.authFromStorage(authData);
          return true;
        } else {
          window.localStorage.removeItem("authData");
          return false;
        }
      }
    } catch (error) {
      console.error("Error on parse SFAUTH");
      console.error(error);
    }
  } else if (rest["isAuthenticated"]) return true;

  return false;
};

function PrivateRoute({ component: Component, authType, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        return isAuthenticated(authType, rest) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
}

function App(props) {
  return (
    <Router>
      <div className="App">
        <Route exact path="/" component={Root} {...props} />
        <PrivateRoute path="/home/:docID?" component={Login} authType={"salesforce_Authenticated"} {...props} />
        <PrivateRoute path="/pesquisa" component={Pesquisa} authType={"isAuthenticated"} {...props} />
        <PrivateRoute path="/oportunidades" component={Oportunities} authType={"isAuthenticated"} {...props} />
        <PrivateRoute path="/conectavantagens" component={Vantagens} authType={"isAuthenticated"} {...props} />
        <PrivateRoute path="/success" component={Success} authType={"isAuthenticated"} {...props} />
        <PrivateRoute path="/welcome/:docID?" component={Welcome} authType={"salesforce_Authenticated"} {...props} />
      </div>
    </Router>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
