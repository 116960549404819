import React, { Component } from "react";
import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/styles";
import { Creators as appActions } from "./ducks/app";
import { Creators as authActions } from "./ducks/auth";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { CustomTextField } from "./components/CustomTextField";
import ErrorAlert from "./ErrorAlert";
import logo from "./images/logo.jpg";
import Loader from "./Loader";
import Modal from "@material-ui/core/Modal";

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...appActions, ...authActions }, dispatch);
}

const mapStateToProps = (state) => {
  return {
    authData: state.auth.authData,
    authError: state.auth.error,
    showAlert: state.auth.showAlert,
    loading: state.auth.loading,
  };
};

const styles = (theme) => ({
  logo: {
    width: "75px",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    height: "100vh",
    alignItems: "center",
    justifyContent: "center",
  },
  modalStyle: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    width: 400,
    padding: theme.spacing(4),
    outline: "none",
  },
});

class Root extends Component {
  constructor() {
    super();
    this.state = {
      showAlert: false,
    };
    this.currentFocusField = null;
    // this._handleKeyPress = this._handleKeyPress.bind(this);
  }

  _handleUserKeyPress = (e) => {
    if (e.charCode === 13) {
      e.preventDefault();
      this.senha.focus();
    }
  };

  _handlePassKeyPress = (e) => {
    if (e.charCode === 13) {
      e.preventDefault();
      this.essilorLogin();
    }
  };

  sfLogin = () => {
    this.props.loginSalesForce();
  };

  essilorLogin = () => {
    const usuario = this.usuario.value;
    const senha = this.senha.value;

    if (usuario && senha) {
      this.props.loginEssilorConecta(usuario, senha, this.props.history);
    }
  };

  hideAlert = () => {
    this.props.hideErrorAlert();
  };

  componentDidUpdate(prevProps, prevState) {
    const { from } = this.props.location.state || { from: null };
    //console.log(from);

    if (prevProps.authData !== this.props.authData && this.props.authData) {
      if (from && from.pathname) {
        let pathCompontents = from.pathname.split("/");
        //console.log(pathCompontents);
        if (
          pathCompontents[1] &&
          (pathCompontents[1] === "home" || pathCompontents[1] === "welcome") &&
          pathCompontents[2] &&
          Number.isInteger(parseInt(pathCompontents[2]))
        ) {
          this.props.history.push(from.pathname);
          return;
        }
      }
      this.props.history.push("/home");
    }
  }

  render() {
    const { classes, authError, showAlert, loading } = this.props;
    return (
      <div>
        {authError && showAlert && <ErrorAlert errorMessage={authError} onConfirm={this.hideAlert} />}
        <Grid container spacing={0} alignItems="flex-start" justify="center">
          <Grid item xs={12} sm={12} className={classes.contentContainer}>
            <Box className={classes.topBar} p={2} pl={5} pr={5} color="white">
              <img src={logo} className={classes.logo} alt="Logo" />
            </Box>
            <Box color="white" fontWeight="fontWeightBold">
              <Typography component="h3" variant="h3">
                Gerador de Valor
              </Typography>
            </Box>
            <Box color="white" mt={3} width={1 / 2}>
              <Box fontSize="h5.fontSize" my={3}>
                Acessar com Essilor Conecta
              </Box>
              <CustomTextField
                label="Usuário"
                name="usuario"
                autoComplete="off"
                className={classes.margin}
                variant="filled"
                id="usuario"
                required
                fullWidth
                autoFocus
                type="text"
                style={{ borderColor: "white" }}
                inputRef={(node) => (this.usuario = node)}
                onKeyPress={this._handleUserKeyPress}
              />
              <CustomTextField
                label="Senha"
                name="senha"
                autoComplete="off"
                className={classes.margin}
                variant="filled"
                id="senha"
                required
                fullWidth
                type="password"
                style={{ borderColor: "white", marginTop: 16 }}
                inputRef={(node) => (this.senha = node)}
                onKeyPress={this._handlePassKeyPress}
              />
              <Button
                style={{ marginTop: 20 }}
                variant="contained"
                color="primary"
                fullWidth
                className={classes.submit}
                onClick={this.essilorLogin}
                size="large">
                Entrar com Essilor Conecta
              </Button>
            </Box>
            <Box color="white" mt={3} width={1 / 2}>
              <Box fontSize="h5.fontSize" my={3}>
                Acessar com Salesforce
              </Box>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                className={classes.submit}
                onClick={this.sfLogin}
                size="large">
                Entrar com Salesforce
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={loading}>
          <div className={classes.modalStyle}>
            <Loader message="Fazendo Login..." />
          </div>
        </Modal>
      </div>
    );
  }

  /*render() {
    const { classes } = this.props;

    return (
      <div>
        <Grid container spacing={0} alignItems="center" justify="center" style={{ height: "100vh" }}>
          <Grid item>
            <Grow in={true} timeout={400}>
              <Box color="white" m={5} lineHeight="normal" fontSize="h6.fontSize">
                Gerador de Valor
              </Box>
              <Box
                color="white"
                fontWeight="fontWeightBold"
                style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <img src={logo} className={classes.logo} alt="Logo" />
                <Box style={{ flexDirection: "row" }}>
                  <Button
                    style={{ width: "200px", marginTop: 20, marginRight: 16 }}
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={this.sfLogin}
                    size="large">
                    Entrar com Salesforce
                  </Button>
                  <Button
                    style={{ width: "200px", marginTop: 20 }}
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={this.essilorLogin}
                    size="large">
                    Entrar com Essilor Conecta
                  </Button>
                </Box>
              </Box>
            </Grow>
          </Grid>
        </Grid>
      </div>
    );
  }*/
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Root));
