import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { fade, makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import { withStyles } from "@material-ui/styles";
import { Creators as appActions } from "./ducks/app";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
import Modal from "@material-ui/core/Modal";
import Header from "./Header";
import ErrorAlert from "./ErrorAlert";
import Loader from "./Loader";

function mapDispatchToProps(dispatch) {
  return bindActionCreators(appActions, dispatch);
}

const mapStateToProps = (state) => {
  return {
    token: state.app.token,
    operating: state.app.operating,
    error: state.app.error,
    showAlert: state.app.showAlert_Login,
  };
};

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  group: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    width: "100px",
    position: "absolute",
    top: theme.spacing(5),
    left: theme.spacing(5),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  modalStyle: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    width: 400,
    padding: theme.spacing(4),
    outline: "none",
  },
});

const useStylesCustomInput = makeStyles((theme) => ({
  root: {
    border: "1px solid #e2e2e1",
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: "#fcfcfb",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&$focused": {
      backgroundColor: "#fff",
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
  focused: {},
}));

function CustomTextField(props) {
  const classes = useStylesCustomInput();

  return <TextField InputProps={{ classes, disableUnderline: true }} {...props} />;
}

class Login extends Component {
  login = () => {
    const docCliente = this.docCliente.value;
    //console.log(this.docCliente.value);
    if (docCliente) {
      this.props.performLogin(docCliente, this.props.history);
    }
  };

  _handleKeyPress = (e) => {
    if (e.charCode === 13) {
      e.preventDefault();
      this.login();
    }
  };

  componentDidMount() {
    const docID = this.props.match.params.docID;
    if (docID) {
      //console.log("docID " + docID);
      this.docCliente.value = docID;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    /* if((prevProps.token !== this.props.token) && this.props.token){
        this.props.history.push('/cluster')
     }*/
  }

  getContent() {
    const { classes } = this.props;
    let content = (
      <form className={classes.form} noValidate>
        <Box color="white" m={3} mt={5}>
          <FormLabel component="legend" style={{ color: "white" }}>
            Informe o CPF (médico) ou CNPJ (óptica)
          </FormLabel>
        </Box>

        <CustomTextField
          label="Documento do Cliente"
          name="docCliente"
          autoComplete="off"
          className={classes.margin}
          variant="filled"
          id="docCliente"
          required
          fullWidth
          autoFocus
          type="text"
          style={{ borderColor: "white" }}
          inputRef={(node) => (this.docCliente = node)}
          onKeyPress={this._handleKeyPress}
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={this.login}
          size="large">
          Entrar
        </Button>
      </form>
    );

    return content;
  }
  render() {
    const { error, showAlert, operating, classes } = this.props;

    return (
      <div>
        {error && showAlert && <ErrorAlert errorMessage={error} onConfirm={() => this.props.hideAlert_Login()} />}
        <Grid container spacing={0} alignItems="center" justify="center" style={{ height: "100vh" }}>
          <Header login />
          <Grid item xs={11} sm={7}>
            <Grow in={true} timeout={400}>
              <Box color="white" fontWeight="fontWeightBold">
                <Typography component="h3" variant="h3">
                  Vamos conversar sobre o seu dia a dia ?
                </Typography>
                {this.getContent()}
              </Box>
            </Grow>
          </Grid>
        </Grid>
        <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={operating}>
          <div className={classes.modalStyle}>
            <Loader message="Carregando Dados..." />
          </div>
        </Modal>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login));
