export const srvrResponse = {
  bool_Retorno: true,
  msg_Retorno: "",
  token:
    "Tdr0srIeOrPcaeumeh4PtHfL5aLBUBBnbBhzOFpNVMfjBwcZLOZu8JlXXr6f2PIRlcrhv3JC6CEj6Qhzx7/BFORhSrvC8cbiEXl9ROl8L0+ZXtD8ueGM8v/CHZNyVQgXiHnk+h8NsLVSD2xI+BIbvEdpk8OgSDFf1YG6nGKp9W8yBlEcMiaY3KPwZ3Sxr9dCcudho7/xQMFv9Lne3kJLwtfmJzZKmdxNsQOS5zuxYBJKazfxAQ0N43kfea9a/Kho/kkqNJza+CWBx3C6WvfbqAACQA+0pOR1tWB7ReTR7snV5IRe4Dxj83NKn2sqlS6FjVV5EYn83AaAQVS5N4OS3VUNhs43jIxkSW4k7274OcRu95XNFqHI/WxJq3iFXj4a3zQ0i1HjLw7rExWWxOvnXzWVbETJ01Wf5rYABPsI/gy4Nh/1ryCxfXEAAPgPlRz8VZvyW3MoQVEBHBRt3YCVZJe9mTXQJUwBFgvdJO5QGHxbW4FQ2CFc4UohSRQoV8BvBTS3+k695lwI116d2PRN0C+RYUcWhmr5NeYuGh0CArPXw88bO1bcY+yxyvPoNTtDulJQGGZCHlMAajYFHPFhjZb1WV5AGAlwpuRgX8mbhM0xWjGlxcxnxV9ijQFpjUtbrnxmjd+VS9dPNA0rXiDmUG2EnQAVaUN10DsZsjhXRZ0eLA3mp623CWr4yVgIT3M9tYiKsuCxyuMrjHPvlyc2TSGVq1b6aSXJKDXRFAWFOKdWwCvkO76mliqdrxksCTVqsIXk2z9xpky2/IgW/ZPkgBygnk2qzZLWhBkW0j5cZhe/tfxgYV89ty/RZsF0YA0AaJfb6jFuWH19cxX4ueLtvd4QYhNXoNxibCnlIfzHgHHz2C8jmrxuz+Ud+LEt9jhWiCi/pHaFb1W6/jwCk8QTmpFykpUu/TgWlmvFFxVUIROUA9pCrgoW+cJoQcxs3d77rKlrJKCvBRBorBQnrlqq6JobnLXaMxQSd3tFuf548Qcp7lnw0CojvbP+k4nQ0BsAiNhjYcOwd78XEksV5kbRbpvvP0cwPr8jmAwB+MTcc7zpVqTbBT03VWlWZVIx8nAj1AmzUCsw6s/dVW73wnnSBooYgeGQom8hA4v5Ra0YT+6gFUlfOylffI3H1JNWxxmu/eqgXU9xW06UkFPTfN6EYZ/QNfXUqedunNW7oIAq1Zo+EImUtluRWEL63anu3pu1749a4iVy8aPsitFL5U4A3yobQXWUsW9k+F0gayWnPC27TRdlh24f8FAxQ9v7b8dZ1zsFbIDxq5PRZg52LrvugRx8lhGp3Z6ZLE6iPHWRP1NDZrJVBc7jMueUcbrroKdMjt87zL/bvJSaSrp2YJkQ8TeVqec258plvfz5ouEYk94CSsVe9jwI3Pyx9OuzCsIK",
  cli: {
    idCluster: 10,
    nomeCluster: "Ótica Especialista A+ A- C+",
    classificao: "Varilux Especialista Platine",
    segmentacao: "A+",
    nomeCliente: "OTICA GERADOR DE VALOR",
    emailCliente: null,
    resultado: true,
    tipoDocumento: "CNPJ",
    perfil: 101,
    uf: "SP",
  },
};

export const oportunities = [
  {
    idPilar: 3,
    pilar: "Transitions",
    simulacoes: [
      {
        idOportunidadeSimulacao: 54,
        title: "Migrando $percentualAumento de suas vendas de varilux incolor para transitions",
        subTitle:
          "Considerando sua média mensal atual de $mediaVendas pares de varilux sem transitions nos ultimos 12 meses",
        percentual: 0.2,
        percentualCaption: "Percentual de aumento projetado Transitions",
        mediaVendas: 10,
        mediaVendasCaption: "Média de pares de transitions atual",
        valorUpgrade: 380.0,
        valorUpgradeCaption: "Valor médido de upgrade Transitions",
        quantidadeUpgrade: 2,
        grafico: {
          titulo: "Titulo do Grafico",
          data: [
            {
              name: "TOP 500",
              value: 120,
              color: "#D5DEFD",
            },
            {
              name: "Média na Região",
              value: 80,
              color: "#B1C2FB",
            },
            {
              name: "Média Nacional",
              value: 45,
              color: "#809FFF",
            },
            {
              name: "Sua Média",
              value: 40,
              color: "#BECEFF",
            },
          ],
        },
      },
      {
        idOportunidadeSimulacao: 30,
        title: "Migrando $percentualAumento de suas vendas de visão simples incolor para Transitions",
        subTitle: "Considerando sua média mensal atual de $mediaVendas pares de visão simples nos ultimos 12 meses",
        percentual: 0.3,
        percentualCaption: "Percentual de aumento projetado Transitions",
        mediaVendas: 30,
        mediaVendasCaption: "Média de pares de Visão Simples atual",
        valorUpgrade: 500.0,
        valorUpgradeCaption: "Valor médido de upgrade Transitions",
        quantidadeUpgrade: 9,
        grafico: {
          titulo: "Titulo do Grafico",
          data: [
            {
              name: "TOP 500",
              value: 120,
              color: "#D5DEFD",
            },
            {
              name: "Média na Região",
              value: 80,
              color: "#B1C2FB",
            },
            {
              name: "Média Nacional",
              value: 45,
              color: "#809FFF",
            },
            {
              name: "Sua Média",
              value: 40,
              color: "#BECEFF",
            },
          ],
        },
      },
    ],
  },
  {
    idPilar: 2,
    pilar: "Kodak",
    simulacoes: [
      {
        idOportunidadeSimulacao: 90,
        title: "Migrando $percentualAumento de suas lentes Espace Plus para Kodak Precise",
        subTitle: "Considerando sua média mensal atual de $mediaVendas pares de Escape Plus nos ultimos 12 meses",
        percentual: 0.25,
        percentualCaption: "Percentual de aumento projetado Transitions",
        mediaVendas: 20,
        mediaVendasCaption: "Média de pares de transitions atual",
        valorUpgrade: 390.0,
        valorUpgradeCaption: "Valor médido de upgrade Transitions",
        quantidadeUpgrade: 5,
        grafico: {
          titulo: "Titulo do Grafico",
          data: [
            {
              name: "TOP 500",
              value: 120,
              color: "#D5DEFD",
            },
            {
              name: "Média na Região",
              value: 80,
              color: "#B1C2FB",
            },
            {
              name: "Média Nacional",
              value: 45,
              color: "#809FFF",
            },
            {
              name: "Sua Média",
              value: 40,
              color: "#BECEFF",
            },
          ],
        },
      },
      {
        idOportunidadeSimulacao: 32,
        title: "Migrando $percentualAumento das lentes Kodak Precise para Kodak Up",
        subTitle: "Considerando que na sua regiao vendem 100 e você só 10",
        percentual: 0.1,
        percentualCaption: "Aumenta log",
        mediaVendas: 40,
        mediaVendasCaption: "Tenho Mais e vocês não sabem",
        valorUpgrade: 100.0,
        valorUpgradeCaption: "É baratinho",
        quantidadeUpgrade: 1,
        grafico: {
          titulo: "Titulo do Grafico",
          data: [
            {
              name: "TOP 500",
              value: 120,
              color: "#D5DEFD",
            },
            {
              name: "Média na Região",
              value: 80,
              color: "#B1C2FB",
            },
            {
              name: "Média Nacional",
              value: 45,
              color: "#809FFF",
            },
            {
              name: "Sua Média",
              value: 40,
              color: "#BECEFF",
            },
          ],
        },
      },
    ],
  },
];

export const saveAnswersOK = {
  sucesso: true,
  urlDocumento: "http://www.orimi.com/pdf-test.pdf",
  chaveSalesForce: "25219679805_2019062912",
  msgErro: "Error",
  username: "mockUser",
};

export const loginError = {
  bool_Retorno: false,
  msg_Retorno: "Cliente não encontrado no Sales Force",
  token: "",
  cli: null,
  perguntas: [],
};

export const loginSF = {
  appId: "3MVG9PE4xB9wtoY.i9KyztjnvBzRmfmGlJuKcOtNiQvRmJd3CHwfJeBpypistXoOuXUhy8laxfDOJnuRja92F",
  accessToken: "00D3D000000AI9C!ARgAQNzS1uIxdxRMURGdyeRxF2ac8rgI_P…N1ntq1NEdk4OyGSO_J2QKeEj70bb3pZ6uZhE1GOB1WnBcvKiG",
  instanceURL: "https://essilorbrasil--full.cs70.my.salesforce.com",
  refreshToken: undefined,
  userId: "005A00000011R78IAE",
};

/*
export const surveyResponse = {
  token:
    "Tdr0srIeOrPcaeumeh4PtHfL5aLBUBBnbBhzOFpNVMfjBwcZLOZu8JlXXr6f2PIRlcrhv3JC6CEj6Qhzx7/BFORhSrvC8cbiEXl9ROl8L0+ZXtD8ueGM8v/CHZNyVQgXiHnk+h8NsLVSD2xI+BIbvEdpk8OgSDFf1YG6nGKp9W8yBlEcMiaY3KPwZ3Sxr9dCcudho7/xQMFv9Lne3kJLwtfmJzZKmdxNsQOS5zuxYBJKazfxAQ0N43kfea9a/Kho/kkqNJza+CWBx3C6WvfbqAACQA+0pOR1tWB7ReTR7snV5IRe4Dxj83NKn2sqlS6FjVV5EYn83AaAQVS5N4OS3VUNhs43jIxkSW4k7274OcRu95XNFqHI/WxJq3iFXj4a3zQ0i1HjLw7rExWWxOvnXzWVbETJ01Wf5rYABPsI/gy4Nh/1ryCxfXEAAPgPlRz8VZvyW3MoQVEBHBRt3YCVZJe9mTXQJUwBFgvdJO5QGHxbW4FQ2CFc4UohSRQoV8BvBTS3+k695lwI116d2PRN0C+RYUcWhmr5NeYuGh0CArPXw88bO1bcY+yxyvPoNTtDulJQGGZCHlMAajYFHPFhjZb1WV5AGAlwpuRgX8mbhM0xWjGlxcxnxV9ijQFpjUtbrnxmjd+VS9dPNA0rXiDmUG2EnQAVaUN10DsZsjhXRZ0eLA3mp623CWr4yVgIT3M9tYiKsuCxyuMrjHPvlyc2TSGVq1b6aSXJKDXRFAWFOKdWwCvkO76mliqdrxksCTVqsIXk2z9xpky2/IgW/ZPkgBygnk2qzZLWhBkW0j5cZhe/tfxgYV89ty/RZsF0YA0AaJfb6jFuWH19cxX4ueLtvd4QYhNXoNxibCnlIfzHgHEdYRx+LJ3Sr8sGf5wrWlqhLFrEkyK3JO7O3erEov5+SGfJ6cJtcdekmGBiXYjqWKKA5VGHvUUJzutNJNATYmfXrz5h5ti/De9JmIPysuXG82RVTI5zX0c+MwmlY4F6PtQYB8HKP3UEhfnW3W7nv9/fAUteovG7Ygw3GhHwZaNEXJ+nCe/U78RoNThJOCP6hKHDsbR+fZGzEEX7UGO7s3J4v5kgG3E33vduX+TTCBnvcqnq+NJ5Jb91WBaFdMurMZVi1wrKe6A/KGCjjzxiGu/JUOwyYdK/gnx60QorNSaZgFAQmRElO3D72gyq4TPVSl5vqwE6LYAMNAr1hnZkfdl1JMYRBdAB6/S0Lcq2ni4AC5oL3mAFbUd1bHBSRjl32AhKedCKEuKhx2RRCMqYdXTQYGxJVEIIShPgpQCMJ6q6XmbdJCvct7NsMKxyOg1GYkWQRcEaYkmFBfx37GbZWHjtkYhW2kkMo0am7wElU+aeqjV7ygaAoGI45BsFLQz7mX4LcSGbK9gMHuZqAqbgftdM",
  idPesquisa: 507,
  perguntas: [],
};
*/

export const surveyResponse = {
  token:
    "Tdr0srIeOrPcaeumeh4PtHfL5aLBUBBnbBhzOFpNVMfjBwcZLOZu8JlXXr6f2PIRlcrhv3JC6CEj6Qhzx7/BFORhSrvC8cbiEXl9ROl8L0+ZXtD8ueGM8v/CHZNyVQgXiHnk+h8NsLVSD2xI+BIbvEdpk8OgSDFf1YG6nGKp9W8yBlEcMiaY3KPwZ3Sxr9dCcudho7/xQMFv9Lne3kJLwtfmJzZKmdxNsQOS5zuxYBJKazfxAQ0N43kfea9a/Kho/kkqNJza+CWBx3C6WvfbqAACQA+0pOR1tWB7ReTR7snV5IRe4Dxj83NKn2sqlS6FjVV5EYn83AaAQVS5N4OS3VUNhs43jIxkSW4k7274OcRu95XNFqHI/WxJq3iFXj4a3zQ0i1HjLw7rExWWxOvnXzWVbETJ01Wf5rYABPsI/gy4Nh/1ryCxfXEAAPgPlRz8VZvyW3MoQVEBHBRt3YCVZJe9mTXQJUwBFgvdJO5QGHxbW4FQ2CFc4UohSRQoV8BvBTS3+k695lwI116d2PRN0C+RYUcWhmr5NeYuGh0CArPXw88bO1bcY+yxyvPoNTtDulJQGGZCHlMAajYFHPFhjZb1WV5AGAlwpuRgX8mbhM0xWjGlxcxnxV9ijQFpjUtbrnxmjd+VS9dPNA0rXiDmUG2EnQAVaUN10DsZsjhXRZ0eLA3mp623CWr4yVgIT3M9tYiKsuCxyuMrjHPvlyc2TSGVq1b6aSXJKDXRFAWFOKdWwCvkO76mliqdrxksCTVqsIXk2z9xpky2/IgW/ZPkgBygnk2qzZLWhBkW0j5cZhe/tfxgYV89ty/RZsF0YA0AaJfb6jFuWH19cxX4ueLtvd4QYhNXoNxibCnlIfzHgHEdYRx+LJ3Sr8sGf5wrWlqhLFrEkyK3JO7O3erEov5+SGfJ6cJtcdekmGBiXYjqWKKA5VGHvUUJzutNJNATYmfXrz5h5ti/De9JmIPysuXG82RVTI5zX0c+MwmlY4F6PtQYB8HKP3UEhfnW3W7nv9/fAUteovG7Ygw3GhHwZaNEXJ+nCe/U78RoNThJOCP6hKHDsbR+fZGzEEX7UGO7s3J4v5kgG3E33vduX+TTCBnvcqnq+NJ5Jb91WBaFdMurMZVi1wrKe6A/KGCjjzxiGu/JUOwyYdK/gnx60QorNSaZgFAQmRElO3D72gyq4TPVSl5vqwE6LYAMNAr1hnZkfdl1JMYRBdAB6/S0Lcq2ni4AC5oL3mAFbUd1bHBSRjl32AhKedCKEuKhx2RRCMqYdXTQYGxJVEIIShPgpQCMJ6q6XmbdJCvct7NsMKxyOg1GYkWQRcEaYkmFBfx37GbZWHjtkYhW2kkMo0am7wElU+aeqjV7ygaAoGI45BsFLQz7mX4LcSGbK9gMHuZqAqbgftdM",
  idPesquisa: 507,
  perguntas: [
    {
      idResposta: 14329,
      area: "Sobre a Essilor",
      conteudo: "Quem Somos",
      descricaoDetalhada: null,
      codResposta: 0,
    },
    {
      idResposta: 14330,
      area: "Sobre a Essilor",
      conteudo: "Essilor - Ótica",
      descricaoDetalhada: "Informações do grupo Essilor no mundo e no Brasil",
      codResposta: 0,
      idVideo: 1,
      urlVideo: "https://www.youtube.com/embed/g7flKMSDG0o?autoplay=1&mute=0",
    },
    {
      idResposta: 14331,
      area: "Sobre a Essilor",
      conteudo: "Laboratórios",
      descricaoDetalhada: "Laboratórios em participação da Essilor e centros de tratamento de antirreflexos",
      codResposta: 0,
      idVideo: 2,
      urlVideo: "https://player.vimeo.com/video/48838193",
    },
    {
      idResposta: 14332,
      area: "Relacionamento - Óticas",
      conteudo: "Programa Varilux Especialista - Ótica",
      descricaoDetalhada:
        "Programa que capacita e apoia óticas na venda dos produtos Essilor e garante ao consumidor produtos e serviços com alto padrão de qualidade",
      codResposta: 0,
      idVideo: 3,
      urlVideo: "https://vimeo.com/48838193",
    },
    {
      idResposta: 14333,
      area: "Relacionamento - Óticas",
      conteudo: "Prêmio Excelência Varilux Especialista",
      descricaoDetalhada:
        "Premiação e reconhecimento de lojas por volume de vendas e ticket médio de determinados produtos",
      codResposta: 0,
    },
    {
      idResposta: 14334,
      area: "Relacionamento - Óticas",
      conteudo: "Varilux Experience",
      descricaoDetalhada: "Experiência impactante para alavancar as vendas de Varilux",
      codResposta: 0,
    },
    {
      idResposta: 14335,
      area: "Relacionamento - Óticas",
      conteudo: "Essilor Conecta e Conecta Vantagens",
      descricaoDetalhada: "Um ambiente único de relacionamento entre a Essilor e as ópticas parceiras",
      codResposta: 0,
    },
    {
      idResposta: 14336,
      area: "Relacionamento - Óticas",
      conteudo: "Promoções para o Mercado",
      descricaoDetalhada: "Campanha em Dobro e Varilux do seu jeito",
      codResposta: 0,
    },
    {
      idResposta: 14337,
      area: "Relacionamento - Óticas",
      conteudo: "Participação em Feiras e Eventos",
      descricaoDetalhada: null,
      codResposta: 0,
    },
    {
      idResposta: 14338,
      area: "Relacionamento - Óticas",
      conteudo: "Aplicativo Essilor em Dobro",
      descricaoDetalhada: "App que auxilia na oferta de lentes da promoção para o consumidor",
      codResposta: 0,
    },
  ],
};

export const conectaLoginResponse = {
  access_token:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJhbGItYXV0aG9yaXphdGlvbi5zZW5zZWRpYS5jb20iLCJpc3MiOiIxMC4wLjEuMTI0Iiwic3ViIjoiMDY0ODE5ZmQtNTg4NS0zNGRiLWI0NzYtODRkNjVmOGJmMWY3IiwiZXhwIjozNjAwLCJpYXQiOjE1NzgzNDMyMjMsIkFwcDogIjoiR2VyYcOnw6NvIGRlIFZhbG9yIC0gTG9jYWxob3N0IERFViIsIkNvZGU6ICI6ImFmYmU2N2IxLWZlMGQtMzQ0Yy04NGJhLWY1ZjgyM2MwNWY1MyIsInVzdWFyaW8iOnsiY3BmIjoiNjc2MzEwOTIwMjgiLCJub21lIjoiVXN1w6FyaW8iLCJzb2JyZW5vbWUiOiI5IiwiZW1haWwiOiJ1c3VhcmlvOUB0ZXN0ZS5jb20ifSwibW9kdWxvcyI6WyJNb2R1bG8gMSJdLCJhcGlzIjpbImdlc3Rhby1wZWRpZG9zLWxhYm9yYXRvcmlvIiwiZ2VzdGFvLXByb2R1dG9zLWxhYm9yYXRvcmlvIl0sImxhYm9yYXRvcmlvcyI6WyI2MDU3MDEwODAwMDE0MSIsIjEwNDcyNDk5MDAwMTkzIl0sImNvbnRhIjoiMjA3MTY1NjMwMDAxOTgifQ.sAeiRiXeBRGvEuBks55F5_XF7daxKg0KWz1nNWU8hT0",
  token_type: "access_token",
  expires_in: 3600,
};
