import React from "react";
import {
  signIn,
  saveSurvey,
  sendEmail,
  OFFLINE,
  apiLoadSurvey,
  apiLoadOportunities,
  saveOportunities,
  sendLogVideo,
} from "../services";
import { cpf, cnpj } from "../validator";
import { normalize, schema } from "normalizr";

const TIPOLOG_EMAIL = "email";
const TIPOLOG_PLAY = "play";

export const Types = {
  PERFORM_LOGIN: "auth/PERFORM_LOGIN",
  PERFORM_LOGIN_SUCCESS: "auth/PERFORM_LOGIN_SUCCESS",
  PERFORM_LOGIN_ERROR: "auth/PERFORM_LOGIN_ERROR",
  LOGOUT: "auth/LOGOUT",

  START_OVER: "app/START_OVER",

  SEND_ANSWERS: "app/SEND_ANSWERS",
  SEND_ANSWERS_SUCCESS: "app/SEND_ANSWERS_SUCCESS",
  SEND_ANSWERS_ERROR: "app/SEND_ANSWERS_ERROR",

  ALERT_CLUSTER_HIDE: "app/ALERT_CLUSTER_HIDE",
  ALERT_LOGIN_HIDE: "app/ALERT_LOGIN_HIDE",

  SAVE_RESULT_PROFILE: "app/SAVE_RESULT_PROFILE",

  SEND_EMAIL: "app/SEND_EMAIL",
  SEND_EMAIL_SUCCESS: "app/SEND_EMAIL_SUCCESS",
  SEND_EMAIL_ERROR: "app/SEND_EMAIL_ERROR",

  LOAD_SURVEY: "app/LOAD_SURVEY",
  LOAD_SURVEY_SUCCESS: "app/LOAD_SURVEY_SUCCESS",
  LOAD_SURVEY_ERROR: "app/LOAD_SURVEY_ERROR",

  LOAD_BUSINESS: "app/LOAD_OPORTUNITIES",
  LOAD_BUSINESS_SUCCESS: "app/LOAD_OPORTUNITIES_SUCCESS",
  LOAD_BUSINESS_ERROR: "app/LOAD_OPORTUNITIES_ERROR",

  SEND_OPORTUNITIES: "app/SEND_OPORTUNITIES",
  SEND_OPORTUNITIES_SUCCESS: "app/SEND_OPORTUNITIES_SUCCESS",
  SEND_OPORTUNITIES_ERROR: "app/SEND_OPORTUNITIES_ERROR",

  UPDATE_SIMULATION: "app/UPDATE_SIMULATION",

  SEND_VIDEO: "app/SEND_VIDEO",
  SEND_VIDEO_SUCCESS: "app/SEND_VIDEO_SUCCESS",
  SEND_VIDEO_ERROR: "app/SEND_VIDEO_ERROR",
};

const INITIAL_STATE = {
  token: null,
  operating: false,
  error: null,
  cli: null,
  docCliente: null,
  pesquisa: null, // {perguntas:[],idPesquisa:int}
  chaveSalesForce: null,
  urlDocumento: null,
  showAlert_Cluster: false,
  showAlert_Login: false,
  resultProfile: null,
  sendingEmail: false,
  sendEmailError: null,
  sendEmailSuccess: false,
  oportunities: null,
  normalizedOportunities: null,
  changedOportunities: [],
  sendOportunitiesSuccess: false,
  sendVideoSuccess: false,
  sendingVideo: false,
  sendVideoError: null,
  currentSubject: null,
};

export default function app(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.START_OVER: {
      return {
        ...state,
        ...INITIAL_STATE,
      };
    }
    case Types.PERFORM_LOGIN: {
      return { ...state, operating: true, docCliente: action.payload };
    }
    case Types.PERFORM_LOGIN_SUCCESS: {
      return { ...state, error: null, operating: false, ...action.payload };
    }
    case Types.PERFORM_LOGIN_ERROR: {
      return {
        ...state,
        error: action.payload,
        operating: false,
        showAlert_Login: true,
        docCliente: null,
      };
    }
    //PESQUISA
    case Types.LOAD_SURVEY: {
      return { ...state, operating: true };
    }
    case Types.LOAD_SURVEY_SUCCESS: {
      const pesquisa = action.payload.data;
      const subject = action.payload.currentSubject;
      return {
        ...state,
        error: null,
        operating: false,
        pesquisa: { ...pesquisa },
        currentSubject: subject,
        //token: pesquisa.token
      };
    }
    case Types.LOAD_SURVEY_ERROR: {
      return {
        ...state,
        error: action.payload,
        operating: false,
        showAlert_Cluster: true,
        currentSubject: null,
      };
    }
    //
    case Types.LOAD_BUSINESS: {
      return { ...state, operating: true, oportunities: null, normalizedOportunities: null, changedOportunities: [] };
    }
    case Types.LOAD_BUSINESS_SUCCESS: {
      const { oportunities, normalizedOportunities } = action.payload;
      return {
        ...state,
        error: null,
        operating: false,
        oportunities,
        normalizedOportunities,
      };
    }
    case Types.LOAD_BUSINESS_ERROR: {
      return {
        ...state,
        error: action.payload,
        operating: false,
        showAlert_Cluster: true,
      };
    }
    case Types.UPDATE_SIMULATION: {
      const { simulationID, newSimulation } = action.payload;
      let idAlreadyExists = state.changedOportunities.indexOf(simulationID) > -1;
      let changedOportunities = state.changedOportunities.slice();
      if (!idAlreadyExists) {
        changedOportunities.push(simulationID);
      }

      return {
        ...state,
        changedOportunities,
        normalizedOportunities: {
          ...state.normalizedOportunities,
          entities: {
            ...state.normalizedOportunities.entities,
            simulation: {
              ...state.normalizedOportunities.entities.simulation,
              [simulationID]: {
                ...state.normalizedOportunities.entities.simulation[simulationID],
                ...newSimulation,
              },
            },
          },
        },
      };
    }
    //
    case Types.SEND_OPORTUNITIES: {
      return { ...state, operating: true, sendOportunitiesSuccess: false };
    }
    case Types.SEND_OPORTUNITIES_SUCCESS: {
      return {
        ...state,
        error: null,
        operating: false,
        sendOportunitiesSuccess: true,
        changedOportunities: [],
      };
    }
    case Types.SEND_OPORTUNITIES_ERROR: {
      return {
        ...state,
        error: action.payload,
        operating: false,
        showAlert_Cluster: true,
        sendOportunitiesSuccess: false,
      };
    }

    //
    case Types.SEND_ANSWERS: {
      return { ...state, operating: true };
    }
    case Types.SEND_ANSWERS_SUCCESS: {
      const { chaveSalesForce, urlDocumento } = action.payload;
      return {
        ...state,
        error: null,
        operating: false,
        chaveSalesForce,
        urlDocumento,
      };
    }
    case Types.SEND_ANSWERS_ERROR: {
      return {
        ...state,
        error: action.payload,
        operating: false,
        showAlert_Cluster: true,
      };
    }
    case Types.ALERT_CLUSTER_HIDE: {
      return { ...state, showAlert_Cluster: false };
    }
    case Types.ALERT_LOGIN_HIDE: {
      return { ...state, showAlert_Login: false };
    }
    case Types.SAVE_RESULT_PROFILE: {
      return { ...state, resultProfile: action.payload };
    }
    case Types.SEND_EMAIL: {
      return {
        ...state,
        sendingEmail: true,
        sendEmailError: false,
        sendEmailSuccess: false,
      };
    }
    case Types.SEND_EMAIL_ERROR: {
      return {
        ...state,
        sendEmailError: action.payload,
        sendingEmail: false,
        sendEmailSuccess: false,
      };
    }
    case Types.SEND_EMAIL_SUCCESS: {
      return {
        ...state,
        sendEmailSuccess: true,
        sendingEmail: false,
        sendEmailError: false,
      };
    }

    case Types.SEND_VIDEO: {
      return {
        ...state,
        sendingVideo: true,
        sendVideoError: false,
        sendVideoSuccess: false,
      };
    }

    case Types.SEND_VIDEO_SUCCESS: {
      return {
        ...state,
        sendVideoSuccess: true,
        sendingVideo: false,
        sendVideoError: false,
      };
    }

    case Types.SEND_VIDEO_ERROR: {
      return {
        ...state,
        sendVideoError: action.payload,
        sendingVideo: false,
        sendVideoSuccess: false,
      };
    }

    default:
      return state;
  }
}

export const getAuthToken = (state) => state.auth.authToken;

export function test() {
  return {
    type: "auth/PERFORM_LOGOUT",
  };
}

function handleClusterError(response) {
  if (response && response.data && response.data.message) {
    let message = response.data.message;
    throw message;
  }

  let err = "Houve um erro ao enviar a pesquisa.";

  if (response && response.problem && response.originalError) {
    err = [<b>Houve um erro ao enviar a pesquisa.</b>, <br />, response.originalError.message];
  }
  throw err;
}

function handleLoginError(response) {
  let err = "Houve um erro ao acessar o documento.";

  if (response && response.problem && response.originalError) {
    err = [<b>Houve um erro ao acessar o documento.</b>, <br />, response.originalError.message];
  }
  throw err;
}

function normalizeOportunities(oportunities) {
  if (!oportunities) return null;
  try {
    const simulation = new schema.Entity("simulation", {}, { idAttribute: "idOportunidadeSimulacao" });
    const oportunitySchema = new schema.Entity(
      "oportunity",
      {
        simulacoes: [simulation],
      },
      { idAttribute: "idPilar" }
    );
    const pilarListSchema = new schema.Array(oportunitySchema);
    const normalizedData = normalize(oportunities, pilarListSchema);
    console.info(normalizedData);
    return normalizedData;
  } catch (error) {
    console.error("Error normalizing oportunities");
    console.error(error);
    return null;
  }
}
export const Creators = {
  startOver: (history) => {
    return function (dispatch) {
      dispatch({
        type: Types.START_OVER,
      });
      history.push("/home");
    };
  },
  restartWithSameClient: (history) => {
    return (dispatch, getState) => {
      const state = getState();
      const { docCliente } = state.app;
      //console.log(`Restarting for ${docCliente} `);

      return dispatch(Creators.performLogin(docCliente, history));
    };
  },
  performLogin: (docClient, history) => {
    return function (dispatch) {
      const docClienteOK = docClient.replace(/[^0-9]/g, "");

      dispatch({
        type: Types.PERFORM_LOGIN,
        payload: docClienteOK,
      });

      if (!OFFLINE) {
        if (!cpf.isValid(docClienteOK) && !cnpj.isValid(docClienteOK)) {
          const err = "CPF ou CNPJ Inválido";
          dispatch({
            type: Types.PERFORM_LOGIN_ERROR,
            payload: err,
          });
          return;
        }
      }

      signIn(docClienteOK)
        .then((response) => {
          if (response.ok && response.data) {
            if (!response.data.bool_Retorno) {
              let message = response.data.msg_Retorno;
              let newResp = {
                problem: true,
                originalError: { message: message },
              };
              handleLoginError(newResp);
            }
            dispatch({
              type: Types.PERFORM_LOGIN_SUCCESS,
              payload: response.data,
            });
            history.push("/welcome");
          } else handleLoginError(response);
        })
        .catch((err) => {
          console.error(err);
          dispatch({
            type: Types.PERFORM_LOGIN_ERROR,
            payload: err,
          });
        });
    };
  },

  sendSurvey: (answers, resultProfile) => {
    return (dispatch, getState) => {
      const state = getState();
      const { token, pesquisa } = state.app;

      dispatch({
        type: Types.SEND_ANSWERS,
      });
      saveSurvey(answers, token, pesquisa.idPesquisa)
        .then((response) => {
          //console.log(response)
          if (response.ok && response.data) {
            if (!response.data.sucesso) {
              let message = response.data.msgErro;
              let newResp = {
                problem: true,
                originalError: { message: message },
              };
              handleClusterError(newResp);
            }
            dispatch({
              type: Types.SAVE_RESULT_PROFILE,
              payload: resultProfile,
            });
            dispatch({
              type: Types.SEND_ANSWERS_SUCCESS,
              payload: response.data,
            });
          } else handleClusterError(response);
        })
        .catch((err) => {
          console.error(err);
          dispatch({
            type: Types.SEND_ANSWERS_ERROR,
            payload: err,
          });
        });
    };
  },
  loadSurvey: (subject) => {
    return (dispatch, getState) => {
      const state = getState();
      const { token } = state.app;
      //console.log(subject);
      dispatch({
        type: Types.LOAD_SURVEY,
      });
      apiLoadSurvey(token, subject.id)
        .then((response) => {
          //console.log(response);
          if (response.ok && response.data) {
            if (!response.data.perguntas || !response.data.perguntas.length) {
              let message = "Pesquisa retornou vazia";
              let newResp = {
                problem: true,
                originalError: { message: message },
              };
              handleClusterError(newResp);
            }
            dispatch({
              type: Types.LOAD_SURVEY_SUCCESS,
              payload: {
                data: response.data,
                currentSubject: subject,
              },
            });
          } else handleClusterError(response);
        })
        .catch((err) => {
          console.error(err);
          dispatch({
            type: Types.LOAD_SURVEY_ERROR,
            payload: err,
          });
        });
    };
  },
  //
  sendOportunities: () => {
    return (dispatch, getState) => {
      const state = getState();
      const { token, normalizedOportunities } = state.app;

      dispatch({
        type: Types.SEND_OPORTUNITIES,
      });

      const allSimulationIDs = Object.keys(normalizedOportunities.entities.simulation);
      // const oportunities = changedOportunities.map(simulationID => {
      const oportunities = allSimulationIDs.map((simulationID) => {
        const simulationEntity = normalizedOportunities.entities.simulation[simulationID];
        const simulation = {
          idOportunidadeSimulacao: simulationID,
          percentual: simulationEntity.percentual,
          mediaVendas: simulationEntity.mediaVendas,
          valorUpgrade: simulationEntity.valorUpgrade,
          quantidadeUpgrade: simulationEntity.quantidadeUpgrade,
        };
        return simulation;
      });

      saveOportunities(token, oportunities)
        .then((response) => {
          //console.log(response)
          if (response.ok) {
            dispatch({
              type: Types.SEND_OPORTUNITIES_SUCCESS,
            });
          } else handleClusterError(response);
        })
        .catch((err) => {
          console.error(err);
          dispatch({
            type: Types.SEND_OPORTUNITIES_ERROR,
            payload: err,
          });
        });
    };
  },
  updateOportunity: (simulationID, payload) => {
    console.info(simulationID);
    console.info(payload);
    return {
      type: Types.UPDATE_SIMULATION,
      payload: {
        simulationID,
        newSimulation: { ...payload },
      },
    };
  },
  loadOportunities: () => {
    return (dispatch, getState) => {
      const state = getState();
      const { token } = state.app;
      dispatch({
        type: Types.LOAD_BUSINESS,
      });
      apiLoadOportunities(token)
        .then((response) => {
          //console.log(response);
          if (response.ok && response.data) {
            dispatch({
              type: Types.LOAD_BUSINESS_SUCCESS,
              payload: {
                oportunities: response.data,
                normalizedOportunities: normalizeOportunities(response.data),
              },
            });
          } else handleClusterError(response);
        })
        .catch((err) => {
          console.error(err);
          dispatch({
            type: Types.LOAD_BUSINESS_ERROR,
            payload: err,
          });
        });
    };
  },

  loginSuccess: (token) => ({
    type: Types.PERFORM_LOGIN_SUCCESS,
    payload: token,
  }),
  loginError: (error) => ({
    type: Types.PERFORM_LOGIN_ERROR,
    payload: error,
  }),
  hideAlert_Cluster: () => ({
    type: Types.ALERT_CLUSTER_HIDE,
  }),
  hideAlert_Login: () => ({
    type: Types.ALERT_LOGIN_HIDE,
  }),
  sendEmail: (email) => {
    return (dispatch, getState) => {
      const state = getState();
      const { token, pesquisa } = state.app;
      sendEmail(token, email, pesquisa.idPesquisa)
        .then((response) => {
          dispatch({
            type: Types.SEND_EMAIL,
          });
          if (response.ok) {
            dispatch({
              type: Types.SEND_EMAIL_SUCCESS,
            });
          } else {
            let message = "Erro desconhecido";
            if (response && response.data && response.data.message) {
              message = response.data.message;
            }
            dispatch({
              type: Types.SEND_EMAIL_ERROR,
              payload: message,
            });
          }
        })
        .catch((err) => {
          console.error(err);
          dispatch({
            type: Types.SEND_EMAIL_ERROR,
            payload: err.message,
          });
        });
    };
  },
  logPlayVideo: (idVideo) => {
    return (dispatch, getState) => {
      const state = getState();
      const { token } = state.app;
      const tipoLog = "play";
      sendLogVideo(token, idVideo, tipoLog, null)
        .then((response) => {
          dispatch({
            type: "sendLogVideoOK",
          });
        })
        .catch((err) => {
          dispatch({
            type: "sendLogVideoERROR",
          });
        });
    };
  },

  logSendVideo: (idVideo, email) => {
    return (dispatch, getState) => {
      const state = getState();
      const { token } = state.app;
      const tipoLog = "email";
      sendLogVideo(token, idVideo, tipoLog, email)
        .then((response) => {
          dispatch({
            type: Types.SEND_VIDEO,
          });
          if (response.ok) {
            dispatch({
              type: Types.SEND_VIDEO_SUCCESS,
            });
          } else {
            let message = "Erro desconhecido";
            if (response && response.data && response.data.message) {
              message = response.data.message;
            }
            dispatch({
              type: Types.SEND_VIDEO_ERROR,
              payload: message,
            });
          }
        })
        .catch((err) => {
          console.error(err);
          dispatch({
            type: Types.SEND_EMAIL_ERROR,
            payload: err.message,
          });
        });
    };
  },
  /*loginSalesForce: () => {
    return (dispatch, getState) => {
      //const state = getState();
      loginSalesForce()
        .then(response => {
          if (response.ok && response.data) {
            response = response.data;
          }
          if (response && response.accessToken) {
            //console.log(response)
            dispatch({
              type: Types.SF_OAUTH_SUCCESS,
              payload: {
                userId: response.userId,
                token: response.accessToken
              }
            });
          } else {
            dispatch({
              type: Types.SF_OAUTH_ERROR
            });
          }
        })
        .catch(err => {
          console.error(err);
          dispatch({
            type: Types.SF_OAUTH_ERROR,
            payload: err.message
          });
        });
    };
  },
  salesForceFromStorage: SF_authObj => ({
    type: Types.SF_OAUTH_FROMSTORAGE,
    payload: SF_authObj
  })*/
};
