import React, { Component } from "react";
import Box from "@material-ui/core/Box";

import { Creators as appActions } from "./ducks/app";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import SweetAlert from "react-bootstrap-sweetalert";

import { withRouter } from "react-router-dom";

function mapDispatchToProps(dispatch) {
  return bindActionCreators(appActions, dispatch);
}

class WaitingAlert extends Component {
  render() {
    return (
      <SweetAlert
        warning
        title="Aguarde"
        onConfirm={this.props.onConfirm}
        closeOnClickOutside={true}
        style={{ width: "50%", left: "25%", marginLeft: 0 }}
        confirmBtnStyle={{
          borderRadius: 3,
          borderColor: "#999",
          borderWidth: 1,
          borderStyle: "solid",
          backgroundColor: "white",
          padding: 10,
          margin: 0,
          minWidth: 100
        }}>
        <Box color="#555">{this.props.errorMessage}</Box>
      </SweetAlert>
    );
  }
}

export default connect(null, mapDispatchToProps)(withRouter(WaitingAlert));
