import React from "react";
import { loginEssilor, loginSalesForce, testSalesForce } from "../services";
import { AUTH_METHODS } from "../constants";

/*AUTHDATA*/
/*
method:string
data:{},
sessionExpires: //customExpiration
userId:salesforceID/username
*/

const expirationInMinutes = 60;

export const Types = {
  SET_AUTH_METHOD: "auth/SET_AUTH_METHOD",
  INIT_AUTH: "auth/INIT_AUTH",
  AUTH_SUCCESS: "auth/AUTH_SUCCESS",
  AUTH_ERROR: "auth/AUTH_ERROR",
  AUTH_FROMSTORAGE: "auth/AUTH_FROMSTORAGE",
  LOGOUT: "auth/LOGOUT",

  HIDE_ALERT: "auth/HIDE_ALERT",
};

const INITIAL_STATE = {
  authData: null,
  error: null,
  showAlert: false,
  loading: false,
};

export default function app(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.AUTH_SUCCESS: {
      const authData = action.payload;
      window.localStorage.setItem("authData", JSON.stringify(authData));
      return { ...state, authData, error: null, showAlert: false, loading: false };
    }
    case Types.AUTH_ERROR: {
      window.localStorage.removeItem("authData");
      //console.log(action.payload);
      return { ...state, authData: null, error: action.payload, showAlert: true, loading: false };
    }
    case Types.AUTH_FROMSTORAGE: {
      return {
        ...state,
        authData: action.payload,
        error: null,
      };
    }
    case Types.INIT_AUTH: {
      return { ...state, loading: true };
    }
    case Types.LOGOUT: {
      window.localStorage.removeItem("authData");
      return { ...state, authData: null, error: null };
    }
    case Types.HIDE_ALERT: {
      return {
        ...state,
        showAlert: false,
      };
    }
    default:
      return state;
  }
}

export const getAuthToken = (state) => state.auth.authToken;

function handleError(response) {
  //console.log("handling error");
  if (response && response.data && response.data.message) {
    let message = response.data.message;
    throw message;
  }

  let err = "Houve um erro ao fazer o login.";

  /*if (response && response.problem && response.originalError) {
    err = [<b>Houve um erro ao fazer o login.</b>, <br />, response.originalError.message];
  }*/
  err = [<b>Houve um erro ao fazer o login.</b>];
  throw err;
}

export const Creators = {
  performLogout: (history) => {
    return function (dispatch) {
      dispatch({
        type: Types.LOGOUT,
      });
      history.push("/");
    };
  },
  loginSalesForce: () => {
    return (dispatch, getState) => {
      //const state = getState();
      loginSalesForce()
        .then((response) => {
          //console.log(response);
          if (response.ok && response.data) {
            response = response.data;
          }
          if (response && response.accessToken) {
            const sessionExpires = new Date().getTime() + expirationInMinutes * 60 * 1000;
            const authData = {
              method: AUTH_METHODS.SALES_FORCE,
              data: response,
              userId: response.userId,
              sessionExpires: sessionExpires,
            };
            dispatch({
              type: Types.AUTH_SUCCESS,
              payload: authData,
            });
          } else {
            handleError(response);
          }
        })
        .catch((err) => {
          console.error(err);
          dispatch({
            type: Types.AUTH_ERROR,
            payload: err,
          });
        });
    };
  },
  authFromStorage: (authObject) => {
    return {
      type: Types.AUTH_FROMSTORAGE,
      payload: authObject,
    };
  },
  hideErrorAlert: () => {
    return {
      type: Types.HIDE_ALERT,
    };
  },
  loginEssilorConecta: (username, password, history) => {
    return (dispatch, getState) => {
      dispatch({
        type: Types.INIT_AUTH,
      });
      loginEssilor(username, password)
        .then((response) => {
          //console.log(response);
          if (response.ok && response.data && response.data.access_token) {
            const sessionExpires = new Date().getTime() + expirationInMinutes * 60 * 1000;
            const authData = {
              method: AUTH_METHODS.ESSILOR_CONECTA,
              data: response.data,
              userId: username,
              sessionExpires: sessionExpires,
            };
            dispatch({
              type: Types.AUTH_SUCCESS,
              payload: authData,
            });
          } else {
            handleError(response);
          }
        })
        .catch((err) => {
          console.error(err);
          dispatch({
            type: Types.AUTH_ERROR,
            payload: err,
          });
        });
    };
  },
};
