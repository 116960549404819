import React, { Component, Fragment } from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import logo from "./images/logo.jpg";
import { Creators as appActions } from "./ducks/app";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { Creators as authActions } from "./ducks/auth";

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...appActions, ...authActions }, dispatch);
}

const useStyles = makeStyles((theme) => ({
  logo: {
    width: "75px",
  },
  topBar: {
    //backgroundColor:'yellow',
    width: "100vw",
    position: "fixed",
    top: 0,
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
  },
  button: {
    backgroundColor: "white",
    marginLeft: "10px",
    textTransform: "none",
    lineHeight: "16px",
    width: "100px",
  },
  backButton: {
    color: "white",
    padding: 0,
  },
}));

class Header extends Component {
  render() {
    return <HeaderFunction {...this.props} />;
  }
}

function HeaderFunction(props) {
  const classes = useStyles();
  function startOver() {
    // props.history.push('/')
    props.startOver(props.history);
  }
  function startSameClient() {
    // props.history.push('/')
    props.restartWithSameClient(props.history);
  }
  function fullLogout() {
    props.performLogout(props.history);
  }
  function goBack() {
    props.history.goBack();
  }
  return (
    <Box
      className={classes.topBar}
      p={2}
      pl={5}
      pr={5}
      color="white"
      style={{ backgroundColor: "#324C78", borderBottom: "1px solid #607090" }}
      zIndex="appBar">
      {/*props.pesquisa && (
        <IconButton aria-label="voltar" className={classes.backButton} onClick={goBack}>
          <ArrowBackIosIcon fontSize="large" />
        </IconButton>
      )*/}
      <img src={logo} className={classes.logo} alt="Logo" />

      {!props.login && (
        <Fragment>
          <Typography component="h1" variant="h5">
            {props.label}
          </Typography>
          <Box style={{ flexDirection: "row", alignItems: "flex-end", justifyContent: "center" }}>
            {props.success && (
              <Button variant="contained" className={classes.button} size="large" onClick={startSameClient}>
                Voltar
              </Button>
            )}
            <Button
              variant="contained"
              className={classes.button}
              size="large"
              onClick={props.backButton ? goBack : startOver}>
              {props.backButton ? "Voltar" : "Novo\nCliente"}
            </Button>
          </Box>
        </Fragment>
      )}

      {props.login && (
        <Button color="primary" onClick={fullLogout}>
          LOGOUT
        </Button>
      )}
    </Box>
  );
}

export default connect(null, mapDispatchToProps)(withRouter(Header));
