export const strings = {
  100: {
    welcome: `Bem-vindo(a), doutor(a)!`,
    welcomeMessage: `
        É um grande prazer para a Essilor ter esta oportunidade de conversarmos.       
        Estou aqui pra oferecer os melhores serviços e soluções para a sua clínica. 
        Vamos conversar sobre como podemos colaborar ainda mais com o doutor(a)?`,
    alreadyKnow: `Você é um super parceiro!`,
    alreadyKnowMessage: `
        Parece que já conhece sobre a Essilor e as vantagens de nossos produtos e serviços.    
        Temos mais oportunidades para continuar colaborando com a sua clínica. Vamos ver?`,
    knowMore: `Temos uma grande oportunidade!`,
    knowMoreMessage: `
        Identificamos muitos pontos para trabalharmos ainda mais próximos e continuarmos apoiando a sua clínica.`
  },
  101: {
    welcome: `Bem-vindo(a)!`,
    welcomeMessage: ` 
        É um grande prazer para a Essilor tê-lo como cliente.
        Estou aqui pra oferecer os melhores serviços e soluções para o seu negócio. 
        Vamos bater um papo sobre como podemos ajudar a sua empresa a crescer mais?`,
    alreadyKnow: `Você é um super parceiro!`,
    alreadyKnowMessage: `
        Parece que já conhece bastante sobre a Essilor e as vantagens de ser nosso parceiro. 
        Temos mais oportunidades para continuar alavancando o seu negócio.
        Vamos ver? `,
    knowMore: `Temos uma grande oportunidade!`,
    knowMoreMessage: `
        Identificamos muitos pontos para um trabalho ainda mais próximo e lucrativo. 
        Vamos juntos definir os próximos passos para que você possa tirar o máximo proveito de tudo o que a Essilor coloca à sua disposição.`
  }
};

export const resultProfiles = {
  ALREADY_KNOW: 100,
  KNOW_MORE: 101
};

export const AUTH_METHODS = {
  ESSILOR_CONECTA: "ESSILOR_CONECTA",
  SALES_FORCE: "SALES_FORCE"
};
