import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger'
import reducers from '../ducks'

let store = null
let middleWares = [ thunk,logger ];
const middleWare = applyMiddleware(...middleWares)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
store = createStore(reducers, composeEnhancers(middleWare))


export default store