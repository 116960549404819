import React, { Component, Fragment } from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import VideocamIcon from "@material-ui/icons/Videocam";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import EmailIcon from "@material-ui/icons/Email";
import { Creators as appActions } from "./ducks/app";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SweetAlert from "react-bootstrap-sweetalert";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Modal from "@material-ui/core/Modal";
import { SegmentedControl } from "./components/SegmentedControl";
import LinearProgress from "@material-ui/core/LinearProgress";

import Header from "./Header";
import ErrorAlert from "./ErrorAlert";
import Loader from "./Loader";
import TextField from "@material-ui/core/TextField";

import { strings, resultProfiles } from "./constants";
import { alertStyle, alertBtnStyle, alertSubmitBtnStyle } from "./components/alert";

function mapDispatchToProps(dispatch) {
  return bindActionCreators(appActions, dispatch);
}

const mapStateToProps = (state) => {
  return {
    data: state.app,
    urlDocumento: state.app.urlDocumento,
    operating: state.app.operating,
    clientEmail: state.app.cli.emailCliente,
    sendingVideo: state.app.sendingVideo,
    sendVideoSuccess: state.app.sendVideoSuccess,
    sendVideoError: state.app.sendVideoError,
  };
};

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  group: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    width: "100px",
  },
  topBar: {
    //backgroundColor:'yellow',
    width: "100vw",
    position: "fixed",
    top: 0,
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
  },
  button: {
    backgroundColor: "white",
    //borderRadius:20,
    textTransform: "none",
  },
  icon: {
    margin: theme.spacing(0.5),
    //fontSize: 56,
    fontSize: 20,
  },
  groupedButton: {
    backgroundColor: "white",
    borderRadius: 0,
    textTransform: "none",
  },
  surveyContainer: {
    backgroundColor: "white",
    //overflowY:'hidden',
    //maxHeight:'100%',
    flexGrow: 1,
    borderRadius: 8,
    marginBottom: 90,
  },
  survey: {
    display: "flex",
    flex: 1,
    alignItems: "stretch",
    flexDirection: "column",
    alignSelf: "stretch",
  },
  footer: {
    height: 90,
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    position: "fixed",
    bottom: 0,
    left: 0,
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    height: "100%",
  },
  modalStyle: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    width: 400,
    padding: theme.spacing(4),
    outline: "none",
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
});

function organizeModelByKey(model, key) {
  var obj = model.reduce(function (acc, x) {
    if (!acc[x[key]]) acc[x[key]] = [];
    acc[x[key]].push(x);
    return acc;
  }, {});
  return obj;
}

class Pesquisa extends Component {
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.urlDocumento !== this.props.urlDocumento && this.props.urlDocumento) {
      this.props.history.push("/success");
    }
  }
  componentDidMount() {
    //const model = organizeModelByKey(this.props.data.perguntas,"area")
    //console.log(model)
  }

  hideAlert = () => {
    this.props.hideAlert_Cluster();
  };

  render() {
    const { error, showAlert_Cluster } = this.props.data;
    return (
      <div>
        {error && showAlert_Cluster && <ErrorAlert errorMessage={error} onConfirm={this.hideAlert} />}
        <StyledCluster {...this.props} />
      </div>
    );
  }
}

//0 nao tem resposta
const options = [
  { label: "Já Conheço", value: 100 }, //100
  { label: "Tenho Interesse", value: 101 }, //101
  { label: "Não Necessito", value: 102 }, //102
];

class Cluster extends Component {
  state = {
    modified: false,
    playVideo: null,
    tituloVideo: null,
    alert: null,
  };

  componentDidMount() {
    this.emailField = React.createRef();
    this.answers = null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.sendingVideo) {
      if (this.props.sendVideoError !== prevProps.sendVideoError && this.props.sendVideoError) {
        this.showErrorAlert();
      }
      if (this.props.sendVideoSuccess !== prevProps.sendVideoSuccess && this.props.sendVideoSuccess) {
        this.showSuccessAlert();
      }
    }
  }

  sendSurvey = () => {
    const currentSubject = this.props.data.currentSubject;
    if (this.answers) {
      let alreadyKnowCount = 0;
      let knowMoreCount = 0;
      let organizedAnswers = Object.keys(this.answers).map((key) => {
        const resposta = this.answers[key];
        if (resposta === 100) alreadyKnowCount += 1;
        if (resposta === 101) knowMoreCount += 1;
        return {
          idResposta: key,
          codResposta: resposta,
        };
      });

      const resultProfile = alreadyKnowCount >= knowMoreCount ? resultProfiles.ALREADY_KNOW : resultProfiles.KNOW_MORE;
      this.props.sendSurvey(organizedAnswers, resultProfile);
    } else {
      if (currentSubject && currentSubject.hideAnswers) {
        const resultProfile = resultProfiles.KNOW_MORE;
        this.props.sendSurvey(null, resultProfile);
      }
    }
  };

  onSetAnswer = (answerID, value) => {
    if (!this.answers) this.answers = {};
    this.answers[answerID] = value;
    if (!this.state.modified) {
      this.setState({
        modified: true,
      });
    }
  };

  onClickVideo = (pergunta) => {
    const { idVideo, conteudo, urlVideo } = pergunta;

    this.setState({ playVideoURL: urlVideo, tituloVideo: conteudo, idVideo: idVideo });
    this.props.logPlayVideo(idVideo);
  };

  renderContent(organizedSurvey) {
    let content = [];
    const currentSubject = this.props.data.currentSubject;

    Object.keys(organizedSurvey).forEach((key) => {
      content.push(<SurveySectionHeader label={key} key={key} />);
      const questionArray = organizedSurvey[key];
      questionArray.forEach((pergunta) => {
        content.push(
          <SurveyCell
            currentSubject={currentSubject}
            pergunta={pergunta}
            key={pergunta.idResposta}
            onSetAnswer={this.onSetAnswer}
            onClickVideo={this.onClickVideo}
          />
        );
      });
    });
    return content.map((content) => content);
  }

  getNomeCliente(client) {
    return client.nomeCliente;
  }

  closeModal = () => {
    this.setState({
      playVideoURL: null,
      tituloVideo: null,
    });
  };

  cancelEmailPrompt = () => {
    this.hideAlert();
  };

  showSendingAlert = () => {
    this.setState({
      alert: (
        <SweetAlert
          style={alertStyle}
          title="Enviando"
          info
          confirmBtnStyle={{ opacity: 0, height: 0 }}
          onConfirm={() => {}}>
          <div style={{ paddingLeft: 20, paddingRight: 20 }}>
            <LinearProgress variant="query" />
          </div>
        </SweetAlert>
      ),
    });
  };

  showErrorAlert = () => {
    const { sendVideoError } = this.props;
    this.setState({
      alert: (
        <SweetAlert
          style={alertStyle}
          title="Erro ao enviar email"
          error
          confirmBtnStyle={alertBtnStyle}
          onConfirm={this.hideAlert}>
          <Box color="#555" style={{ marginBottom: 20 }}>
            {sendVideoError && sendVideoError}
          </Box>
        </SweetAlert>
      ),
    });
  };

  showSuccessAlert = () => {
    this.setState({
      alert: (
        <SweetAlert
          style={alertStyle}
          title="Email enviado"
          success
          confirmBtnStyle={alertBtnStyle}
          onConfirm={this.hideAlert}></SweetAlert>
      ),
    });
  };

  clickedSendEmailPrompt = () => {
    const email = this.emailField.current.value;
    if (!email || email.length < 2) {
      return;
    }
    const { idVideo } = this.state;
    this.showSendingAlert();
    this.props.logSendVideo(idVideo, email);
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  sendVideo = () => {
    this.setState({
      alert: (
        <SweetAlert
          style={alertStyle}
          showCancel
          confirmBtnStyle={alertSubmitBtnStyle}
          cancelBtnStyle={alertBtnStyle}
          title="Enviar link por email"
          placeHolder="email"
          defaultValue="email@email.com"
          onConfirm={this.clickedSendEmailPrompt}
          onCancel={this.cancelEmailPrompt}
          closeOnClickOutside={true}
          cancelBtnText="Cancelar">
          <Box color="#555" style={{ marginBottom: 20 }}>
            Email de destino
          </Box>
          <div style={{ paddingLeft: 20, paddingRight: 20 }}>
            <TextField
              id="text-field-email"
              inputRef={this.emailField}
              defaultValue={this.props.clientEmail}
              fullWidth={true}
            />
          </div>
        </SweetAlert>
      ),
    });
  };

  isButtonDisabled = (modified) => {
    let diabled = false;
    const currentSubject = this.props.data.currentSubject;

    if (currentSubject && currentSubject.hideAnswers) {
      diabled = false;
    } else diabled = modified ? false : true;

    return diabled;
  };

  render() {
    const { classes } = this.props;
    const client = this.props.data.cli;
    const pesquisa = this.props.data.pesquisa;
    const perguntas = pesquisa.perguntas;
    const organizedSurvey = organizeModelByKey(perguntas, "area");

    const buttonDisabled = this.isButtonDisabled(this.state.modified);

    return (
      <Fragment>
        {this.state.alert}

        <Grid container spacing={0} alignItems="flex-start" justify="center" style={{ paddingTop: 120 }}>
          <Header label={this.getNomeCliente(client)} style={{ backgroundColor: "#194F7C" }} backButton />
          <Grid item xs={12} sm={12} className={classes.contentContainer}>
            <Box color="white" fontWeight="fontWeightBold">
              <Typography component="h3" variant="h3">
                {strings[client.perfil].welcome}
              </Typography>
            </Box>
            <Box color="white" m={5} lineHeight="normal" fontSize="h6.fontSize">
              {strings[client.perfil].welcomeMessage}
            </Box>
            <Box m={3} color="white" className={classes.survey}>
              <Paper className={classes.surveyContainer}>{this.renderContent(organizedSurvey)}</Paper>
            </Box>
          </Grid>
          <Box className={classes.footer} pl={3} pr={3}>
            <Button
              disabled={buttonDisabled}
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={this.sendSurvey}
              size="large">
              Enviar
            </Button>
          </Box>
          <Modal aria-labelledby="Salvando" aria-describedby="Salvando Pesquisa" open={this.props.operating}>
            <div className={classes.modalStyle}>
              <Loader message="Salvando pesquisa..." />
            </div>
          </Modal>

          <Modal
            aria-labelledby="Video"
            aria-describedby="Video"
            open={this.state.playVideoURL ? true : false}
            disableEnforceFocus>
            <div
              style={{
                margin: "auto",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <div
                id="modal-video-content"
                style={{
                  backgroundColor: "white",
                  padding: "24px",
                  minHeight: "40%",
                  maxHeight: "90%",
                  width: "90vw",
                  height: "60vw",
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                  borderRadius: "16px",
                }}>
                <div
                  id="close"
                  style={{
                    position: "absolute",
                    backgroundColor: "white",
                    top: 8,
                    right: 0,
                  }}>
                  <Button
                    style={{ backgroundColor: "#FFFFFF" }}
                    variant="contained"
                    onClick={this.closeModal}
                    disableElevation>
                    <HighlightOffIcon fontSize="large" style={{ color: "black" }} />
                  </Button>
                </div>
                <Box
                  color="black"
                  fontWeight="fontWeightBold"
                  textAlign="left"
                  display="flex"
                  alignItems="center"
                  fontSize="h5.fontSize">
                  {this.state.tituloVideo}
                </Box>
                <div id="video" style={{ flex: 1, marginTop: "16px", marginBottom: "16px" }}>
                  <iframe
                    style={{ border: 0 }}
                    width="100%"
                    height="100%"
                    src={this.state.playVideoURL}
                    frameborder="0"
                    webkitallowfullscreen
                    mozallowfullscreen
                    allowfullscreen></iframe>
                </div>
                <div style={{ alignSelf: "flex-end" }}>
                  <Button
                    style={{ backgroundColor: "#FFFFFF" }}
                    variant="contained"
                    color="primary"
                    disableElevation
                    className={classes.button}
                    onClick={this.sendVideo}
                    startIcon={<EmailIcon style={{ color: "rgb(0,122,255)" }} />}>
                    <span style={{ color: "rgb(0,122,255)" }}>Enviar por email</span>
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
        </Grid>
      </Fragment>
    );
  }
}

const StyledCluster = withStyles(styles)(Cluster);

function SurveySectionHeader(props) {
  return (
    <Fragment>
      <Box color="#303030" p={2} fontSize="h4.fontSize" mt={1} mb={2}>
        {props.label}
      </Box>
    </Fragment>
  );
}

class SurveyCell extends Component {
  changeSegmentValue = (index) => {
    const { pergunta } = this.props;
    //Value 100,101,102
    if (this.props.onSetAnswer) {
      let value = index + 100;
      this.props.onSetAnswer(pergunta.idResposta, value);
    }
  };

  playVideo = (pergunta) => {
    if (this.props.onClickVideo) {
      this.props.onClickVideo(pergunta);
    }
  };

  renderVideo = () => {
    const { pergunta } = this.props;
    if (pergunta && pergunta.urlVideo && pergunta.idVideo)
      return (
        <Button
          style={{ backgroundColor: "#FFFFFF" }}
          variant="contained"
          onClick={() => this.playVideo(pergunta)}
          disableElevation>
          <VideocamIcon fontSize="large" style={{ color: "black" }} />
        </Button>
      );
    else return null;
  };

  render() {
    const { pergunta } = this.props;
    const { currentSubject } = this.props;

    const hideAnswers = currentSubject ? currentSubject.hideAnswers : null;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          borderBottomWidth: 1,
          borderBottomColor: "#D1D1D8",
          borderBottomStyle: "solid",
          minHeight: 64,
        }}>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}>
          <Box
            color="black"
            fontWeight="fontWeightBold"
            style={{ flex: 1 }}
            textAlign="left"
            pl={3}
            display="flex"
            alignItems="center"
            fontSize="h5.fontSize">
            {pergunta.conteudo}
            {this.renderVideo()}
          </Box>
          {!hideAnswers && (
            <Box style={{ width: 360 }} pr={3}>
              <SegmentedControl
                segments={options}
                selected={null}
                variant="essilor"
                onChangeSegment={this.changeSegmentValue}
                size="normal"
                weight="semibold"
              />
            </Box>
          )}
        </div>
        {pergunta.descricaoDetalhada && (
          <Box color="#75757B" pl={3} pr={3} mb={3} textAlign="left">
            {pergunta.descricaoDetalhada}
          </Box>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Pesquisa));
