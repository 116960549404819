import React, { Component, Fragment } from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/styles";
import { withRouter } from "react-router-dom";

import { Creators as appActions } from "./ducks/app";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Modal from "@material-ui/core/Modal";

import Header from "./Header";
import ErrorAlert from "./ErrorAlert";
import Loader from "./Loader";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Slider from "@material-ui/core/Slider";
import InputAdornment from "@material-ui/core/InputAdornment";
import SweetAlert from "react-bootstrap-sweetalert";
import ChartDialog from "./ChartDialog";
import BarChartIcon from "@material-ui/icons/BarChart";

function mapDispatchToProps(dispatch) {
  return bindActionCreators(appActions, dispatch);
}

const mapStateToProps = (state) => {
  return {
    data: state.app,
    operating: state.app.operating,
    oportunities: state.app.oportunities,
    normalizedOportunities: state.app.normalizedOportunities,
    sendOportunitiesSuccess: state.app.sendOportunitiesSuccess,
    changedOportunities: state.app.changedOportunities,
  };
};

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  group: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    width: "100px",
  },
  topBar: {
    //backgroundColor:'yellow',
    width: "100vw",
    position: "fixed",
    top: 0,
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
  },
  button: {
    backgroundColor: "white",
    //borderRadius:20,
    textTransform: "none",
  },
  icon: {
    margin: theme.spacing(0.5),
    fontSize: 56,
  },
  groupedButton: {
    backgroundColor: "white",
    borderRadius: 0,
    textTransform: "none",
  },
  surveyContainer: {
    backgroundColor: "white",
    //overflowY:'hidden',
    //maxHeight:'100%',
    flexGrow: 1,
    borderRadius: 8,
  },
  survey: {
    display: "flex",
    flex: 1,
    alignItems: "stretch",
    flexDirection: "column",
    alignSelf: "stretch",
  },
  footer: {
    height: 90,
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    position: "fixed",
    bottom: 0,
    left: 0,
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    height: "100%",
    paddingBottom: 90,
  },
  modalStyle: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    width: 400,
    padding: theme.spacing(4),
    outline: "none",
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
});

const alertStyle = { width: "50%", left: "25%", marginLeft: 0 };
const alertBtnStyle = {
  borderRadius: 3,
  borderColor: "#999",
  borderWidth: 1,
  borderStyle: "solid",
  backgroundColor: "white",
  padding: 10,
  minWidth: 100,
  margin: 10,
};

class Oportunities extends Component {
  state = {
    alert: null,
  };

  hideSuccessAlert = () => {
    this.setState({
      alert: null,
    });
  };

  showSuccessAlert = () => {
    this.setState({
      alert: (
        <SweetAlert
          style={alertStyle}
          title="Simulações salvas com sucesso"
          success
          confirmBtnStyle={alertBtnStyle}
          onConfirm={this.hideSuccessAlert}></SweetAlert>
      ),
    });
  };

  componentDidMount() {
    //this.props.loadOportunities();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.sendOportunitiesSuccess !== prevProps.sendOportunitiesSuccess &&
      this.props.sendOportunitiesSuccess
    ) {
      this.showSuccessAlert();
    }
  }

  hideAlert = () => {
    this.props.hideAlert_Cluster();
  };

  render() {
    const { error, showAlert_Cluster } = this.props.data;
    return (
      <div>
        {this.state.alert}
        {error && showAlert_Cluster && <ErrorAlert errorMessage={error} onConfirm={this.hideAlert} />}
        <StyledCluster {...this.props} />
      </div>
    );
  }
}

function SurveySectionHeader(props) {
  return (
    <Fragment>
      <Box color="#303030" p={2} fontSize="h3.fontSize" fontWeight="bold" mt={1}>
        {props.label}
      </Box>
    </Fragment>
  );
}

class MainPage extends Component {
  state = {
    currentSimulationID: null,
    openDialog: false,
    openChartDialog: false,
  };

  getNomeCliente(client) {
    return client.nomeCliente;
    //return "Nome do Cliente";
  }

  renderContent = () => {
    return <div />;
  };

  renderSimulationTitle = (simulation) => {
    let simulationTitle = "";
    try {
      //OLD PERCENTAGE
      //let percentage = simulation.percentual * 100 + "%";
      //simulationTitle = simulation.title.replace("$percentualAumento", percentage);

      let migration = Math.round(simulation.percentual * simulation.mediaVendas);
      let replaxe = "";
      if (migration === 1) {
        replaxe = migration + " par";
      } else {
        replaxe = migration + " pares";
      }
      simulationTitle = simulation.title.replace("$percentualAumento", replaxe);
    } catch (error) {}
    return simulationTitle;
  };

  renderSimulationSubTitle = (simulation) => {
    let simulationSubTitle = "";
    try {
      simulationSubTitle = simulation.subTitle.replace("$mediaVendas", simulation.mediaVendas);
    } catch (error) {}
    return simulationSubTitle;
  };

  renderMoney = (simulation) => {
    let money = "";
    try {
      //money = simulation.mediaVendas * (1 + simulation.percentual) * simulation.valorUpgrade * 12;
      money = Math.round(simulation.percentual * simulation.mediaVendas) * simulation.valorUpgrade * 12;

      money = money.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    } catch (error) {}
    return money;
  };

  changeSimulation = (idPilar, idSimulation) => {
    console.info(idPilar);
    console.info(idSimulation);

    this.openDialog(idSimulation);
  };

  renderSimulation = (simulation, idPilar) => {
    //console.info(idPilar);
    return (
      <Box width={1} py={2} px={4} key={simulation.idOportunidadeSimulacao}>
        <Box color="#444" display="flex">
          <Box width="50%" textAlign="left" fontSize={22} lineHeight={1.5}>
            {this.renderSimulationTitle(simulation)}
          </Box>
          <Box display="flex" alignItems="center" p={1} m={1} width="10%">
            <ArrowForwardIosIcon style={{ fontSize: 60 }} />
          </Box>
          <Box textAlign="right" width="40%" color="text.primary">
            <Box
              fontWeight="fontWeightBold"
              fontSize="h3.fontSize"
              letterSpacing={-1}
              style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              {this.renderMoney(simulation)}
            </Box>
            <Box fontSize="h5.fontSize">ao ano</Box>
          </Box>
        </Box>
        <Box color="#444" display="flex" my={3}>
          <Box width="70%" textAlign="left" fontSize={16}>
            {this.renderSimulationSubTitle(simulation)}
          </Box>
          <Box width="30%" textAlign="right">
            <Button
              style={{ backgroundColor: "#e3b77c", marginRight: 8, paddingLeft: 2, paddingRight: 2 }}
              variant="contained"
              color="white"
              onClick={() => this.openChart(idPilar, simulation.idOportunidadeSimulacao)}>
              <BarChartIcon fontSize="medium" style={{ color: "white" }} />
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.changeSimulation(idPilar, simulation.idOportunidadeSimulacao)}>
              Alterar
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };

  renderTotalMoney = (oportunities) => {
    const { normalizedOportunities } = this.props;
    let total = 0;

    oportunities.forEach((oportunity) => {
      oportunity.simulacoes.forEach((simulationID) => {
        let simulation = {};
        if (normalizedOportunities && normalizedOportunities.entities && normalizedOportunities.entities.simulation) {
          simulation = normalizedOportunities.entities.simulation[simulationID];
          //let money = simulation.mediaVendas * (1 + simulation.percentual) * simulation.valorUpgrade * 12;
          let money = Math.round(simulation.percentual * simulation.mediaVendas) * simulation.valorUpgrade * 12;

          total += money;
        }
      });
    });

    const totalMoney = total.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    return totalMoney;
  };
  renderTotal = (oportunities) => {
    const { classes } = this.props;
    if (!oportunities || !oportunities.length) {
      return <div />;
    }

    return (
      <Box px={3} m={2} mb={3} className={classes.survey} key="total">
        <Paper className={classes.surveyContainer}>
          <Box width={1} py={2} px={4}>
            <Box color="#444" display="flex">
              <Box
                width="50%"
                textAlign="left"
                fontSize={40}
                lineHeight={1.5}
                alignItems="center"
                display="flex"
                fontWeight="fontWeightBold"
                flexDirection="column"
                alignItems="flex-start">
                TOTAL
                <Typography style={{ lineHeight: "20px" }}>
                  Simulações não contratuais, baseadas nas compras junto a laboratórios Essilor
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" p={1} m={1} width="10%">
                <ArrowForwardIosIcon style={{ fontSize: 60 }} />
              </Box>
              <Box
                textAlign="right"
                width="40%"
                color="text.primary"
                alignItems="flex-end"
                justifyContent="center"
                display="flex"
                flexDirection="column">
                <Box
                  fontWeight="fontWeightBold"
                  fontSize="h3.fontSize"
                  letterSpacing={-1}
                  style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                  {this.renderTotalMoney(oportunities)}
                </Box>
                <Box fontSize="h5.fontSize">ao ano</Box>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    );
  };

  openChart = (idPilar, idSimulation) => {
    this.setState({
      currentSimulationID: idSimulation,
      openChartDialog: true,
    });
  };

  closeChartDialog = () => {
    this.setState({
      openChartDialog: false,
      currentSimulationID: null,
    });
  };

  openDialog = (idSimulation) => {
    this.setState({
      currentSimulationID: idSimulation,
      openDialog: true,
    });
  };

  closeDialog = () => {
    this.setState({
      openDialog: false,
      currentSimulationID: null,
    });
  };

  render() {
    const { classes, normalizedOportunities, changedOportunities } = this.props;
    const { openDialog, openChartDialog, currentSimulationID } = this.state;

    const client = this.props.data.cli;

    let oportunities = [];
    if (
      normalizedOportunities &&
      normalizedOportunities.entities &&
      normalizedOportunities.entities.oportunity &&
      normalizedOportunities.result
    ) {
      oportunities = normalizedOportunities.result.map((key) => {
        return normalizedOportunities.entities.oportunity[key];
      });
    }

    const currentSimulation = normalizedOportunities
      ? normalizedOportunities.entities.simulation[currentSimulationID]
      : null;

    //console.info(oportunities);

    return (
      <Grid container spacing={0} alignItems="flex-start" style={{ paddingTop: 120 }}>
        <Header label={this.getNomeCliente(client)} style={{ backgroundColor: "#194F7C" }} backButton />
        <Grid item xs={12} sm={12} className={classes.contentContainer}>
          <Box color="white" fontWeight="fontWeightBold">
            <Typography component="h3" variant="h3">
              Oportunidades para seu Negócio
            </Typography>
          </Box>
          <Box color="white" m={5} lineHeight="normal" fontSize="h6.fontSize">
            Aumentar o faturamento de sua loja não depende só de vender mais, mas também de vender melhor. Vamos
            identificar o seu potencial de vendas?
          </Box>
          {oportunities &&
            oportunities.map((oportunity) => {
              return (
                <Box px={3} m={2} mb={3} className={classes.survey} key={oportunity.idPilar.toString()}>
                  <Paper className={classes.surveyContainer}>
                    <SurveySectionHeader label={oportunity.pilar.toUpperCase()} />
                    {oportunity.simulacoes.map((simulationID) => {
                      let simulation = {};
                      if (
                        normalizedOportunities &&
                        normalizedOportunities.entities &&
                        normalizedOportunities.entities.simulation
                      ) {
                        simulation = normalizedOportunities.entities.simulation[simulationID];
                      }
                      return this.renderSimulation(simulation, oportunity.idPilar);
                    })}
                  </Paper>
                </Box>
              );
            })}
          {this.renderTotal(oportunities)}
          <Box className={classes.footer} pl={3} pr={3}>
            <Button
              fullWidth
              disabled={changedOportunities.length ? false : false}
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => this.props.sendOportunities()}
              size="large">
              Salvar
            </Button>
          </Box>
          <ChartDialog
            openDialog={openChartDialog}
            handleCloseDialog={this.closeChartDialog}
            oportunity={currentSimulation}
          />
          <SimulationDialog
            currentSimulationID={currentSimulationID}
            openDialog={openDialog}
            handleCloseDialog={this.closeDialog}
            {...this.props}
          />
          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.props.operating}>
            <div className={classes.modalStyle}>
              <Loader message="Salvando simulações..." />
            </div>
          </Modal>
        </Grid>
      </Grid>
    );
  }
}

function DialogLabel(props) {
  return (
    <Box flex={1} fontSize={16}>
      {props.label}
    </Box>
  );
}

function DialogInputField(props) {
  return (
    <Box flex={1} textAlign="left" ml={4}>
      <TextField
        style={{ width: "50%" }}
        id="outlined-number"
        type="number"
        variant="outlined"
        value={props.value}
        onChange={props.onChangeValue}
        InputProps={
          props.currency
            ? {
                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
              }
            : null
        }
      />
    </Box>
  );
}

class SimulationDialog extends Component {
  state = {
    currentSimulation: null,
  };

  componentWillReceiveProps(nextProps) {
    //console.log(nextProps);
    const { normalizedOportunities } = nextProps;
    const currentSimulation = normalizedOportunities
      ? normalizedOportunities.entities.simulation[nextProps.currentSimulationID]
      : null;
    console.info("componentWillReceiveProps");
    console.info(currentSimulation);

    const mediaVendas = currentSimulation ? currentSimulation.mediaVendas : "";
    const percentual = currentSimulation ? currentSimulation.percentual : 0;
    let migration = "";
    if (mediaVendas && percentual) {
      migration = Math.round(percentual * mediaVendas);
    }

    this.setState({
      currentSimulation: currentSimulation,
      mediaVendas,
      valorUpgrade: currentSimulation ? currentSimulation.valorUpgrade : "",
      percentual,
      migration,
    });
  }

  closeDialog = (shouldSave) => {
    if (shouldSave) {
      const { mediaVendas, valorUpgrade, percentual } = this.state;
      const quantidadeUpgrade = Math.round(percentual * mediaVendas);
      this.props.updateOportunity(this.props.currentSimulationID, {
        mediaVendas,
        valorUpgrade,
        percentual,
        quantidadeUpgrade,
      });
    }
    this.props.handleCloseDialog();
  };

  onChangeMediaVendas = (event) => {
    const mediaVendas = event.target.value;

    this.setState({
      mediaVendas,
      migration: Math.round(this.state.percentual * mediaVendas),
    });
  };

  onChangeValorUpdgrade = (event) => {
    this.setState({
      valorUpgrade: event.target.value,
    });
  };

  onChangeMigration = (event) => {
    let migrationValue = event.target.value;
    //console.info(migrationValue);
    //console.info(this.state.mediaVendas);
    if (Number(migrationValue) > Number(this.state.mediaVendas)) {
      migrationValue = this.state.mediaVendas;
    }
    //console.info(migrationValue);

    const percentual = migrationValue / this.state.mediaVendas;
    //console.info(percentual);
    this.setState({
      percentual,
      migration: migrationValue,
    });
  };

  onSliderChange = (event, newValue) => {
    const percentual = newValue / 100;

    this.setState({
      percentual,
    });
  };

  onPercentualSliderChange = (event, newValue) => {
    const percentual = newValue / 100;

    this.setState({
      percentual,
      migration: Math.round(percentual * this.state.mediaVendas),
    });
  };

  valuetext = (value) => {
    return `${value}`;
  };

  render() {
    const { currentSimulation, mediaVendas, valorUpgrade, percentual, migration } = this.state;

    return (
      <Dialog open={this.props.openDialog} onClose={this.props.handleCloseDialog} fullWidth={true}>
        <DialogTitle id="form-dialog-title">Simulação</DialogTitle>
        <DialogContent>
          <Box m={2} display="flex" alignItems="center">
            <DialogLabel label={currentSimulation ? currentSimulation.mediaVendasCaption : ""} />
            <DialogInputField value={mediaVendas} onChangeValue={this.onChangeMediaVendas} />
          </Box>
          <Box m={2} display="flex" alignItems="center">
            <DialogLabel label={currentSimulation ? currentSimulation.valorUpgradeCaption : ""} />
            <DialogInputField value={valorUpgrade} onChangeValue={this.onChangeValorUpdgrade} currency />
          </Box>
          <Box mb={0} mt={6} mx={2} display="flex" alignItems="center">
            <DialogLabel label={currentSimulation ? currentSimulation.percentualCaption : ""} />
            <Box flex={1} ml={4}>
              <Slider
                value={typeof percentual === "number" ? Math.round(percentual * 100) : 0}
                getAriaValueText={this.valuetext}
                aria-labelledby="discrete-slider-always"
                step={1}
                min={0}
                max={100}
                valueLabelDisplay="on"
                onChange={this.onSliderChange}
                onChangeCommitted={this.onPercentualSliderChange}
              />
            </Box>
          </Box>
          <Box m={2} display="flex" alignItems="center">
            <DialogLabel label="Migração de pares por mês" />
            <DialogInputField value={migration} onChangeValue={this.onChangeMigration} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Box m={2}>
            <Button onClick={() => this.closeDialog(false)} color="primary">
              Cancelar
            </Button>
            <Button onClick={() => this.closeDialog(true)} color="primary">
              Alterar
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    );
  }
}

const StyledCluster = withStyles(styles)(MainPage);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Oportunities));
