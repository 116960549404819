import { create } from "apisauce";
import MockAdapter from "axios-mock-adapter";
import { srvrResponse, saveAnswersOK, loginSF, surveyResponse, oportunities, conectaLoginResponse } from "../__mock__";
import { OAuth } from "forcejs";
import { AUTH_METHODS } from "../constants";

export const OFFLINE = 0;

const CONFIG = window.Configs;
let useConfig = null;

if (CONFIG) useConfig = CONFIG.useConfig;

let baseURL = null;
let timeout = 30000;
let salesforceURL = null;
let salesforceAppID = null;

let essilorLoginEndpoint = null;

if (CONFIG && useConfig) {
  const configuration = CONFIG[useConfig];
  baseURL = configuration.serverURL + ":" + configuration.port + configuration.apiURL;
  salesforceURL = configuration.salesForce;
  salesforceAppID = configuration.salesForceAppID;
  essilorLoginEndpoint = configuration.essilorLoginEndpoint;

  if (configuration.timeout) {
    timeout = configuration.timeout;
  }
}

var headers = {
  Accept: "application/json",
  "Content-Type": "application/json; charset=utf-8",
};

const api = create({
  baseURL: baseURL,
  timeout: timeout,
  headers: headers,
  crossDomain: true,
});

// const apiEssilorConecta = create({
//   baseURL: "https://api.essilor.com.br",
//   timeout: timeout,
//   headers: {
//     Accept: "application/json",
//     "Content-Type": "application/x-www-form-urlencoded",
//     client_id: "064819fd-5885-34db-b476-84d65f8bf1f7",
//     Authorization:
//       "Basic MDY0ODE5ZmQtNTg4NS0zNGRiLWI0NzYtODRkNjVmOGJmMWY3OjFkODhlYjA3LTA3ZWItMzJlMi1iNDJkLWQwOGYwYzc0YjY3OQ==",
//   },
//   crossDomain: true,
// });

const apiEssilorConecta = create({
  baseURL: "https://api.essilor.com.br",
  timeout: timeout,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/x-www-form-urlencoded",
    client_id: "ac6558ee-ccd7-302d-a470-bce3bf3e03db",
    Authorization:
      "Basic YWM2NTU4ZWUtY2NkNy0zMDJkLWE0NzAtYmNlM2JmM2UwM2RiOmIwMGJjOWNhLWZhMDktM2M0Zi1iOTJiLTg4OGFjZTYxYTBmZg==",
  },
  crossDomain: true,
});

export function testSalesForce(authObject) {
  return;
}

export function loginSalesForce() {
  if (OFFLINE) {
    return api.get("/PesquisaAPI/FakeLoginSalesForce", {});
  }

  let loginURL = salesforceURL;
  let appId = salesforceAppID;

  const context = window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/"));
  const baseURL =
    window.location.protocol +
    "//" +
    window.location.hostname +
    (window.location.port ? ":" + window.location.port : "") +
    context;
  const oauthCallbackURL = baseURL + "/oauthcallback.html";

  let oauth = OAuth.createInstance(appId, loginURL, oauthCallbackURL);
  return oauth.login();

  //simulate login
  /*let response = {};
  response.ok = true;
  response.data = loginSF;
  return Promise.resolve(response);*/
}

export function loginEssilor(user, pass) {
  /*let testUser = "usuario9@teste.com";
  let testPass = "Essilor@2019";
  const reqOBJx = {
    usuario: testUser,
    senha: testPass
  };

  console.log(reqOBJx);*/

  const reqOBJ = {
    usuario: user,
    senha: pass,
  };

  //console.log(reqOBJ);

  return apiEssilorConecta.post(essilorLoginEndpoint, reqOBJ);
}

export function signIn(docClient) {
  /*
  ValidaDocumentos?docCliente={CPFouCNPJ}&usr={SalesForceID}&usrName={Name}
  */
  const authData = JSON.parse(window.localStorage.getItem("authData"));
  if (!authData) return;
  //console.log(authData);
  let reqOBJ = {
    docCliente: docClient,
  };

  const userId = authData.userId;
  if (authData.method === AUTH_METHODS.SALES_FORCE) {
    reqOBJ.usr = userId;
  }
  if (authData.method === AUTH_METHODS.ESSILOR_CONECTA) {
    reqOBJ.usrName = userId;
    reqOBJ.tokenEssilorConecta = authData.data.access_token;
  }

  //console.log("REQ OBJ:", reqOBJ);
  return api.post("/PesquisaAPI/ValidaDocumentos", reqOBJ);
}

export function apiLoadOportunities(token) {
  const reqOBJ = {
    token: token,
  };
  return api.post("/OportunidadeAPI/CriaSimulacao", reqOBJ);
}

export function apiLoadSurvey(token, subject) {
  const reqOBJ = {
    token: token,
    Tema: subject,
  };

  //console.info(reqOBJ);
  return api.post("/PesquisaAPI/CriaPesquisa", reqOBJ);
}

export function saveSurvey(answers, token, idPesquisa) {
  const reqOBJ = {
    token: token,
    perguntas: answers ? answers : [],
    idPesquisa: idPesquisa,
  };

  //console.log("save survey");

  return api.post("/PesquisaAPI/EnviaResposta", reqOBJ);
}

export function saveOportunities(token, oportunities) {
  const reqOBJ = {
    token: token,
    simulacoes: oportunities,
  };
  console.info(reqOBJ);
  return api.post("/OportunidadeAPI/EnviaSimulacao", reqOBJ);
}

export function sendEmail(token, email, idPesquisa) {
  const reqOBJ = {
    token: token,
    email: email,
    idPesquisa: idPesquisa,
  };

  return api.post("/PesquisaAPI/EnviaEmail", reqOBJ);
}

export function sendLogVideo(token, idVideo, tipoLog, email) {
  const reqOBJ = {
    token: token,
    email: email,
    idVideo: idVideo,
    tipoLog: tipoLog,
  };

  return api.post("/PesquisaAPI/LogVideo", reqOBJ);
}

//MOCK
// const mailError = {
//   message: "Endereço de E-mail Não cadastrado no Sales Force",
// };

if (OFFLINE) {
  var mock = new MockAdapter(api.axiosInstance, { delayResponse: 100 });
  mock.onPost("/PesquisaAPI/ValidaDocumentos").reply(200, srvrResponse);
  mock.onGet("/PesquisaAPI/FakeLoginSalesForce").reply(200, loginSF);
  mock.onPost("/PesquisaAPI/CriaPesquisa").reply(200, surveyResponse);
  mock.onPost("/PesquisaAPI/EnviaResposta").reply(200, saveAnswersOK);
  mock.onPost("/PesquisaAPI/EnviaEmail").reply(200, {});
  mock.onPost("/OportunidadeAPI/CriaSimulacao").reply(200, oportunities);
  mock.onPost("/OportunidadeAPI/EnviaSimulacao").reply(200, {});
  mock.onPost("/PesquisaAPI/LogVideo").reply(200, {});
  //mock.onPost("/OportunidadeAPI/EnviaSimulacao").reply(400, mailError);
  //mock.onPost('/EnviaEmail').reply(400, mailError);

  var mockEssilorConecta = new MockAdapter(apiEssilorConecta.axiosInstance, { delayResponse: 100 });
  mockEssilorConecta.onPost("/dev/autenticacao-jwt/v1/token").reply(200, conectaLoginResponse);
}
