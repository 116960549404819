import React, { Component } from "react";
import Button from "@material-ui/core/Button";

import Box from "@material-ui/core/Box";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { BarChart, Bar, Cell, ResponsiveContainer, Legend } from "recharts";

const defaultColors = ["#D5DEFD", "#B1C2FB", "#809FFF", "#BECEFF"];

const renderLegend = (props) => {
  const { payload } = props;

  return (
    <Box
      width={250}
      height={150}
      color="gray"
      display="flex"
      alignItems="flex-start"
      justifyContent="space-between"
      flexDirection="column"
      ml={4}>
      {payload.map((entry, index) => (
        <Box
          key={`item-${index}`}
          display="flex"
          justifyContent="space-between"
          width="100%"
          borderBottom={"1px solid #CCC"}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
            <div
              style={{
                borderRadius: "50%",
                width: 12,
                height: 12,
                backgroundColor: entry.color ? entry.color : defaultColors[index % defaultColors.length],
                marginRight: 8,
              }}
            />
            <Box fontSize={16}> {entry.name}</Box>
          </div>
          <Box fontSize={16}> {entry.value}</Box>
        </Box>
      ))}
    </Box>
  );
};

class ChartDialog extends Component {
  closeDialog = () => {
    this.props.handleCloseDialog();
  };

  render() {
    const { oportunity } = this.props;
    if (!oportunity || !oportunity.grafico) return <div />;
    const grafico = oportunity.grafico;
    return (
      <Dialog open={this.props.openDialog} onClose={this.props.handleCloseDialog} fullWidth={true}>
        <DialogTitle id="form-dialog-title">{grafico.titulo}</DialogTitle>
        <DialogContent>
          <Box m={2} display="flex" alignItems="center">
            <ResponsiveContainer height={150}>
              <BarChart data={grafico.data} margin={{ top: 15, right: 5, bottom: 5, left: 5 }}>
                <Legend layout="vertical" align="right" content={renderLegend} payload={grafico.data} />
                <Bar dataKey="value" label={{ position: "top", fill: "gray" }} unit="Number">
                  {grafico.data.map((entry, index) => {
                    return (
                      <Cell
                        key={`cell-${index}`}
                        fill={entry.color ? entry.color : defaultColors[index % defaultColors.length]}
                      />
                    );
                  })}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box m={2}>
            <Button onClick={() => this.closeDialog()} color="primary">
              Fechar
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ChartDialog;
