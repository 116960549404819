export const alertStyle = { width: "50%", left: "25%", marginLeft: 0 };

export const alertBtnStyle = {
  borderRadius: 3,
  borderColor: "#999",
  borderWidth: 1,
  borderStyle: "solid",
  backgroundColor: "white",
  padding: 10,
  minWidth: 100,
  margin: 10,
};

export const alertSubmitBtnStyle = {
  borderRadius: 3,
  borderColor: "#999",
  borderWidth: 1,
  borderStyle: "solid",
  padding: 10,
  margin: 0,
  minWidth: 100,
  backgroundColor: "#007AC5",
  color: "#FFF",
};
