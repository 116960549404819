import React, { Component } from "react";

import { withStyles } from "@material-ui/styles";
import { withRouter } from "react-router-dom";

import { Creators as appActions } from "./ducks/app";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Grid from "@material-ui/core/Grid";

import Header from "./Header";

function mapDispatchToProps(dispatch) {
  return bindActionCreators(appActions, dispatch);
}

const mapStateToProps = (state) => {
  return {
    data: state.app,
    docCliente: state.app.docCliente,
    operating: state.app.operating,
  };
};

const styles = (theme) => ({
  button: {
    backgroundColor: "white",
    textTransform: "none",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    height: "100%",
  },
});

class Vantagens extends Component {
  state = {};

  componentDidMount() {}

  render() {
    return (
      <div>
        <StyledScene {...this.props} />
      </div>
    );
  }
}

class Scene extends Component {
  componentDidMount() {}

  getNomeCliente(client) {
    return client.nomeCliente;
  }

  render() {
    const { docCliente } = this.props;
    const client = this.props.data.cli;

    const iFrameSource = "https://vantagens.essilorconecta.com.br/gerador?cnpj=" + docCliente;
    return (
      <Grid container spacing={0} alignItems="flex-start" justify="center" style={{ paddingTop: 120, height: "100vh" }}>
        <Header label={this.getNomeCliente(client)} style={{ backgroundColor: "#194F7C" }} backButton />
        <div style={{ flex: 1, padding: 24, paddingTop: 0, height: "100%" }}>
          <iframe
            id="conectavantagens"
            title="Conecta Vantagens"
            width="100%"
            height="100%"
            style={{ border: 0, borderRadius: 4 }}
            src={iFrameSource}></iframe>
        </div>
      </Grid>
    );
  }
}

const StyledScene = withStyles(styles)(Scene);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Vantagens));
