import React, { Component } from "react";
import Fade from "@material-ui/core/Fade";
import Box from "@material-ui/core/Box";
import { RotateSpinner } from "react-spinners-kit";

export default class Loader extends Component {
  render() {
    return (
      <Fade in={true}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <RotateSpinner color="white" />
          <Box color="white" m={3} fontSize="h5.fontSize" fontWeight="fontWeightBold" textAlign="center">
            {this.props.message}
          </Box>
        </Box>
      </Fade>
    );
  }
}
