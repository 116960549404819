import React, { Component, Fragment } from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/styles";
import { Creators as appActions } from "./ducks/app";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Grid from "@material-ui/core/Grid";
import Header from "./Header";
import Lottie from "react-lottie";
import animationData from "./animation/stars1.json";
import Grow from "@material-ui/core/Grow";
import Slide from "@material-ui/core/Slide";
import { strings, resultProfiles } from "./constants";
import SweetAlert from "react-bootstrap-sweetalert";
import LinearProgress from "@material-ui/core/LinearProgress";
import TextField from "@material-ui/core/TextField";
import Loader from "./Loader";
import Modal from "@material-ui/core/Modal";
import { alertStyle, alertBtnStyle, alertSubmitBtnStyle } from "./components/alert";

function mapDispatchToProps(dispatch) {
  return bindActionCreators(appActions, dispatch);
}

function openTab(url) {
  // Create link in memory
  var a = window.document.createElement("a");
  a.target = "_blank";
  a.href = url;

  // Dispatch fake click
  var e = window.document.createEvent("MouseEvents");
  e.initMouseEvent("click", true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
  a.dispatchEvent(e);
}

const mapStateToProps = (state) => {
  return {
    urlDocumento: state.app.urlDocumento,
    clientProfile: state.app.cli.perfil,
    resultProfile: state.app.resultProfile,
    clientEmail: state.app.cli.emailCliente,
    clientName: state.app.cli.nomeCliente,
    sendingEmail: state.app.sendingEmail,
    sendEmailSuccess: state.app.sendEmailSuccess,
    sendEmailError: state.app.sendEmailError,
    operating: state.app.operating,
  };
};

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  group: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    width: "100px",
  },
  topBar: {
    width: "100vw",
    position: "fixed",
    top: 0,
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
  },
  button: {
    backgroundColor: "white",
    //borderRadius:20,
    textTransform: "none",
  },
  icon: {
    margin: theme.spacing(0.5),
    fontSize: 56,
  },
  groupedButton: {
    backgroundColor: "white",
    borderRadius: 0,
    textTransform: "none",
  },
  modalStyle: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    width: 400,
    padding: theme.spacing(4),
    outline: "none",
  },
});

const defaultLottieOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

class Success extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
    };
    this.emailField = React.createRef();
  }

  openDoc = () => {
    const { urlDocumento } = this.props;
    if (!urlDocumento) {
      alert("Documento não encontrado");
      return;
    }

    openTab(urlDocumento);
    //window.open(urlDocumento, '_blank');
  };
  shareDoc = () => {
    const { urlDocumento } = this.props;
    if (navigator.share) {
      navigator
        .share({
          title: "Essilor - PDF",
          url: urlDocumento,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing:", error));
    } else {
      alert("Versão de browser não suportada");
      //console.log('CANT share')
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.sendingEmail) {
      if (this.props.sendEmailError !== prevProps.sendEmailError && this.props.sendEmailError) {
        this.showErrorAlert();
      }
      if (this.props.sendEmailSuccess !== prevProps.sendEmailSuccess && this.props.sendEmailSuccess) {
        this.showSuccessAlert();
      }
    }
  }

  contato = () => {
    window.open("https://essilorsolutions.com.br/fale-conosco/", "_blank");
  };
  sendEmail = () => {
    this.showEmailPrompt();
  };
  getTitle() {
    const { clientProfile, resultProfile } = this.props;
    if (resultProfile === resultProfiles.ALREADY_KNOW) return strings[clientProfile].alreadyKnow;
    else return strings[clientProfile].knowMore;
  }
  getMessage() {
    const { clientProfile, resultProfile } = this.props;
    if (resultProfile === resultProfiles.ALREADY_KNOW) return strings[clientProfile].alreadyKnowMessage;
    else return strings[clientProfile].knowMoreMessage;
  }

  //EMAIL

  cancelEmailPrompt = () => {
    this.hideAlert();
  };

  clickedSendEmailPrompt = () => {
    const email = this.emailField.current.value;
    if (!email || email.length < 2) {
      return;
    }

    this.showSendingAlert();
    this.props.sendEmail(email);
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  showErrorAlert = () => {
    const { sendEmailError } = this.props;
    this.setState({
      alert: (
        <SweetAlert
          style={alertStyle}
          title="Erro ao enviar email"
          error
          confirmBtnStyle={alertBtnStyle}
          onConfirm={this.hideAlert}>
          <Box color="#555" style={{ marginBottom: 20 }}>
            {sendEmailError && sendEmailError}
          </Box>
        </SweetAlert>
      ),
    });
  };

  showSuccessAlert = () => {
    this.setState({
      alert: (
        <SweetAlert
          style={alertStyle}
          title="Email enviado"
          success
          confirmBtnStyle={alertBtnStyle}
          onConfirm={this.hideAlert}></SweetAlert>
      ),
    });
  };

  showSendingAlert = () => {
    this.setState({
      alert: (
        <SweetAlert
          style={alertStyle}
          title="Enviando"
          info
          confirmBtnStyle={{ opacity: 0, height: 0 }}
          onConfirm={() => {}}>
          <div style={{ paddingLeft: 20, paddingRight: 20 }}>
            <LinearProgress variant="query" />
          </div>
        </SweetAlert>
      ),
    });
  };

  showEmailPrompt = () => {
    const { clientEmail } = this.props;

    this.setState({
      alert: (
        <SweetAlert
          style={alertStyle}
          showCancel
          confirmBtnStyle={alertSubmitBtnStyle}
          cancelBtnStyle={alertBtnStyle}
          title="Enviar por email"
          placeHolder="email"
          defaultValue="email@email.com"
          onConfirm={this.clickedSendEmailPrompt}
          onCancel={this.cancelEmailPrompt}
          closeOnClickOutside={true}
          cancelBtnText="Cancelar">
          <Box color="#555" style={{ marginBottom: 20 }}>
            Email de destino
          </Box>
          <div style={{ paddingLeft: 20, paddingRight: 20 }}>
            <TextField id="text-field-email" inputRef={this.emailField} defaultValue={clientEmail} fullWidth={true} />
          </div>
        </SweetAlert>
      ),
    });
  };

  render() {
    const { classes, clientName } = this.props;
    return (
      <Fragment>
        {this.state.alert}
        <Grid container spacing={0} alignItems="center" justify="center" style={{ height: "100vh" }}>
          <Header success label={clientName} />
          <Grid item xs={12} sm={10}>
            <Grow in={true} timeout={500}>
              <Box color="white" fontWeight="fontWeightBold">
                <Typography component="h1" variant="h3">
                  {this.getTitle()}
                </Typography>
              </Box>
            </Grow>
            <Box color="white" m={5} lineHeight="normal" fontSize="h6.fontSize">
              {this.getMessage()}
            </Box>
            <Box m={3} color="white">
              <Lottie options={defaultLottieOptions} height={100} width={400} />
            </Box>
            <Slide in={true} timeout={500} direction="up">
              <Box p={5} style={{ display: "flex", flexDirection: "column" }}>
                <Button
                  size="large"
                  fullWidth
                  variant="contained"
                  className={classes.groupedButton}
                  style={{ borderTopLeftRadius: 4, borderTopRightRadius: 4 }}
                  onClick={this.openDoc}>
                  Ver Apresentação
                </Button>
                <Button
                  size="large"
                  fullWidth
                  variant="contained"
                  className={classes.groupedButton}
                  onClick={this.sendEmail}>
                  Enviar por E-mail
                </Button>
                <Button
                  size="large"
                  fullWidth
                  variant="contained"
                  className={classes.groupedButton}
                  onClick={this.shareDoc}>
                  Compartilhar
                </Button>
                <Button
                  size="large"
                  fullWidth
                  variant="contained"
                  className={classes.groupedButton}
                  style={{ borderBottomLeftRadius: 4, borderBottomRightRadius: 4 }}
                  onClick={this.contato}>
                  Contato Essilor Solutions
                </Button>
              </Box>
            </Slide>
            <Modal
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={this.props.operating}>
              <div className={classes.modalStyle}>
                <Loader message="Iniciando pesquisa..." />
              </div>
            </Modal>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Success));
