import { red } from "@material-ui/core/colors";
import { responsiveFontSizes } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    blue: {
      main: "#444477"
    },
    primary: {
      main: "#007AC5"
    },
    secondary: {
      main: "#19757b"
    },
    error: {
      main: red.A400
    },
    background: {
      default: "#fff"
    },
    headTable: {
      main: "#CDCFF2"
    }
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ["-apple-system", "BlinkMacSystemFont", '"Helvetica Neue"', "Arial", "sans-serif"].join(",")
  }
  /* overrides: {
    MuiTextField: {
      root: {
        
        fontWeight: "bold",
        backgroundColor: "red",
        margin: "10px",
        "&:hover": {
          backgroundColor: "green"
        }
      },input: {
        color: "white"
      }
    }
  }*/
});

export default responsiveFontSizes(theme); //theme;
